import React from 'react';
import st from "./Blog.module.css";

const Blog = () => {
    return (
        <div className={st.container}>
            <span className={st.title}>Lorem ipsum dolor sit amet?</span>
            <span className={st.text}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci alias aperiam atque cupiditate deserunt error est ex explicabo labore, magni modi necessitatibus nemo nihil non nostrum nulla odio quas quisquam quod repellat repellendus sint sit soluta tenetur unde voluptates! Ad aliquam aspernatur assumenda atque beatae blanditiis commodi consequatur cupiditate distinctio dolor doloremque dolorum eius eos eum expedita explicabo fuga illo impedit itaque laborum magni minus nam necessitatibus obcaecati, odit optio pariatur perferendis praesentium quasi quibusdam quis repudiandae rerum saepe sit ullam veritatis voluptate! Architecto autem delectus esse fuga natus nesciunt, optio ratione similique sint soluta suscipit ullam voluptatem voluptatibus!</span>
            <hr className={st.blogHR}/>
            <img src={require("../../image/gallery/gallery6.jpg")} style={{float: 'right'}} className={st.image} alt={'blogPhoto'}/>
            <span className={st.text}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab accusantium alias aliquid architecto, aspernatur assumenda consectetur deserunt ducimus eos est excepturi expedita hic incidunt laudantium libero minima mollitia nihil omnis pariatur porro quam, quisquam repellat repellendus reprehenderit, sapiente similique tempora ullam unde voluptas! Cumque cupiditate doloremque, excepturi hic iusto molestiae nemo obcaecati perspiciatis quas, sequi suscipit temporibus? Consequuntur cum eius eum eveniet ipsa laudantium molestias optio praesentium sunt voluptatum!</span>
            <hr className={st.blogHR}/>
            <span className={st.text}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi cupiditate deleniti doloribus dolorum expedita facere fuga harum ipsum itaque quo. Aliquam error esse, laborum modi natus quae quo repellat similique? Deserunt, dicta eaque earum eveniet impedit, ipsam iusto labore nulla numquam omnis quidem ratione, sequi suscipit temporibus ullam voluptates voluptatibus! Alias amet aperiam aut dicta doloribus enim esse eveniet excepturi exercitationem fuga id ipsa libero, magnam molestiae nesciunt nihil officiis optio perspiciatis placeat possimus rerum suscipit tenetur unde ut, velit!</span>
            <img src={require("../../image/gallery/gallery7.jpg")} style={{float: 'left'}} className={st.image} alt={'blogPhoto'}/>
            <span className={st.text}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, assumenda commodi consequuntur cum dolore dolorum eaque eos est eum, fuga fugiat, iusto labore laborum natus necessitatibus nemo nobis numquam odio perspiciatis porro possimus quam quisquam reprehenderit sit soluta ullam velit?</span>
            <hr className={st.blogHR}/>
            <span className={st.text}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias dicta dolorum ex modi provident, totam veritatis. At eius expedita illo ipsam molestias nam obcaecati odio, tempora velit. Eos natus nihil porro quia tempora! Cum dolorem ducimus explicabo labore laborum libero nihil non quis? Doloremque dolorum earum eligendi eos fugit harum laborum, magni, minima nam nobis porro quia ratione reiciendis rem suscipit vel veritatis voluptates! Aliquid autem, cum, dolorem ea eveniet iure maiores maxime minima minus necessitatibus odio possimus voluptatibus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aliquid animi asperiores at atque deleniti deserunt esse eveniet harum id incidunt iste, labore molestiae nam natus nihil, nostrum numquam odit perspiciatis quae quos ratione repellat reprehenderit sapiente soluta sunt vel? Aliquid consequuntur delectus deleniti hic id nam nulla quia soluta?</span>
        </div>
    );
};

export default Blog;