import React, {useEffect, useRef} from 'react';
import st from "./BlogCard.module.css";
import {motion} from 'framer-motion';
import {Link} from "react-router-dom";

const BlogCard = ({ blog }) => {

    const ref = useRef(null);

    // анимация
    const animation = {
        hidden: {
            opacity: 0,
        },
        visible: custom => ({
            opacity: 1,
            transition: {
                delay: custom * 0.1,
            },
        }),
    }

    const colorBlogs = () => {
        const width = document.documentElement.clientWidth;
        if (width <= 670) {
            ref.current.children[2].children[0].style.color = blog.titleColorAfterHover;
            ref.current.children[2].children[1].style.color = blog.textColorAfterHover;
            ref.current.children[3].style.color = blog.dateColorAfterHover;
        }
        else {
            ref.current.children[2].children[0].style.color = blog.titleColor;
            ref.current.children[2].children[1].style.color = blog.textColor;
            ref.current.children[3].style.color = blog.dateColor;
        }
    }

    useEffect(() => {
        colorBlogs()
    })


    useEffect(() => {
        window.addEventListener('resize', colorBlogs);
        return () => {
            window.removeEventListener('resize', colorBlogs);
        };
    });


    const hover = (e) => {
        const width = document.documentElement.clientWidth;
        if (width > 670) {
            const elem = e.target;
            elem.children[2].children[0].style.color = blog.titleColorAfterHover;
            elem.children[2].children[1].style.color = blog.textColorAfterHover;
            elem.children[3].style.color = blog.dateColorAfterHover;
        }
    }

    const unHover = (e) => {
        const width = document.documentElement.clientWidth;
        if (width > 670) {
            const elem = e.target;
            elem.children[2].children[0].style.color = blog.titleColor;
            elem.children[2].children[1].style.color = blog.textColor;
            elem.children[3].style.color = blog.dateColor;
        }
    }

    return (
        <Link to={`/blog/${blog.id}`}>
            <motion.div ref={ref} className={st.blog} onMouseEnter={hover} onMouseLeave={unHover} initial="hidden" whileInView="visible" custom={10} variants={animation} viewport={{amount: 0, once: true}}>


                <img src={blog.image} alt={"postPhoto"} className={st.img}/>
                <div className={st.glass}></div>

                <div className={st.textBlock}>
                    <span className={st.blogTitle} style={{color: `${blog.titleColor}`}}>{blog.title}</span>
                    <span className={st.blogText} style={{color: `${blog.textColor}`}}>{blog.text}</span>
                </div>
                <span className={st.date} style={{color: `${blog.dateColor}`}}>{blog.date}</span>



            </motion.div>
        </Link>
    );
};

export default BlogCard;