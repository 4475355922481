import React from 'react';
import st from "./MyFilterButton.module.css";
import cn from "classnames";

const MyFilterButton = ({ filters, onChange, type, keyValue, text}) => {
    return (
        <div onClick={() => onChange(type, keyValue)} className={cn(st.filterButton, {[st.filterButtonHovered]: filters[keyValue] === true})}>
            <span>{text}</span>
        </div>
    );
};

export default MyFilterButton;