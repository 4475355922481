import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import st from './MiniProject.module.css';
import {ReactComponent as Area} from "../../../image/icons/area.svg";
import {ReactComponent as Calendar} from "../../../image/icons/calendar.svg";
import {ReactComponent as Eye} from "../../../image/icons/eye.svg";
import MiniProjectLine from "../MiniProjectLine/MiniProjectLine";
import axios from "../../../axios";
import Loader from "../Loader/Loader";
import ProjectSkeleton from "../ProjectSkeleton/ProjectSkeleton";
import {Link} from "react-router-dom";
import Logo from "../../../image/logo";
import cn from "classnames";
// import {signalContext} from "../../../Context";

export const MiniProject = ({project, isLoading, width}) => {

    // текущее фото
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    //  словарь с данными о фотографиях, в том числе с blob ссылкой
    const [photoUrl, setPhotoUrl] = useState({})

    // получаем картинки для мини проекта
    useEffect(() => {
        const controller = new AbortController();
        const fetchPhoto = async () => {
            if (project && project.photos) { // Проверяем существование project и photos
                for (let i = 0; i < project.photos.length; i++) {
                    axios
                        .get(`/photos/${project.photos[i].id}`, {
                            signal: controller.signal,
                            responseType: 'blob'
                        })
                        .then((response) => {

                            // Создаем временный URL из объекта Blob
                            photoUrl[project.photos[i].previewPosition - 1] = URL.createObjectURL(response.data);

                            // Обновляем состояние с новым массивом фотографий
                            setPhotoUrl({ ...photoUrl});
                        });
                }
            }
        }
        fetchPhoto();
        return () => {
            controller.abort();
        };
    }, [])

    if (isLoading) {
        return <ProjectSkeleton />;
    }

    // метод изменения фотографии сзади при ведении мышкой
    const setBackgroundPhoto = (id) => {
        setCurrentPhotoIndex(id)
    }


    // анимация
    const animation = {
        hidden: {
            opacity: 0,
        },
        visible: custom => ({
            opacity: 1,
            transition: {
                delay: custom * 0.1,
            },
        }),
    }
    let a = width

    // style={{width: `calc((100% / 3) - ${width}px)`}}
    // при изменении currentPhotoIndex будет изменяться бэкграунд проекта
    return (
        <motion.div initial="hidden" whileInView="visible" custom={2} viewport={{amount: 0, once: true}}
                    variants={animation} className={st.frame} 
                    onMouseLeave={() => setCurrentPhotoIndex(0)}>
            <Link to={`/projects/${project.id}`}>
                <div className={st.project}>
                    {photoUrl[currentPhotoIndex] ?
                        <>
                            <div className={st.insetShadow}></div>
                            <img src={photoUrl[currentPhotoIndex]} alt={"miniPhoto"} className={cn(st.projectPhoto, {[st.projectPhotoLoaded]: photoUrl[currentPhotoIndex] !== ""})}/>
                            <span>{project.name}</span>
                        </>
                        :
                        <>
                            <div className={st.loadBG}>
                                <Logo width={"25%"} height={"25%"}/>
                            </div>
                            {/*<Loader width={60}/>*/}
                        </>
                    }

                </div>
                <div className={st.params}>
                    <div className={st.param}>
                        <Area width={21}/>
                        <span>{project.area + "м²"}</span>
                    </div>
                    <div className={st.param}>
                        <Calendar width={20}/>
                        <span>{project.date.split('-').reverse().join('.')}</span>
                    </div>
                    <div className={st.param}>
                        <Eye width={20}/>
                        <span>{project.popular}</span>
                    </div>
                </div>
                <div className={st.lineBoxes}>
                    {project.photos.map((i, id) =>
                        <MiniProjectLine key={id} id={id} onMove={setBackgroundPhoto}
                                         numOfLines={project.photos.length}/>
                    )}
                </div>
            </Link>
        </motion.div>
    );
};
