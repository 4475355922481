import React, {useEffect, useRef, useState} from 'react';
import Preview from "../../components/Preview/Preview";
import AboutUs from "../../components/AboutUs/AboutUs";
import st from "./Main.module.css";
import {MiniProject} from "../../components/UI/MiniProject/MiniProject";
import axios from "../../axios";
import {Link} from "react-router-dom";
import HowWeWork from "../../components/HowWeWork/HowWeWork";
import {useTheme} from '../../hooks/useTheme'
// import {signalContext} from "../../Context";
import {ReactComponent as Swipe} from "../../image/icons/swipe.svg";

export const Main = () => {
    const {theme, setTheme} = useTheme();
    const container = useRef();
    let initialTouchX;
    let lastTouchDeltaX;
    let touchStartX = 0;
    let touchMoveX = 0;
    let int;
    const [otherProjectsInfo, setOtherProjectsInfo] = useState([])

    useEffect(() => {
        let config = {
            // headers: {'Authorization': 'JWT ' + this.$store.state.token},
            params: {
                number: 6
            },
        }
        axios.get(`/projects/random`, config).then(res => {
            setOtherProjectsInfo(res.data)
            console.log(res.data)
        });
    }, [])


    const handleTouchStart = (event) => {
        // Сохраняем начальную позицию касания
        initialTouchX = event.touches[0].pageX;
        touchStartX = event.touches[0].pageX;
    };
    
    // useEffect(() => {
    //     console.log(theme)
    // }, [theme])

    const handleTouchMove = (event) => {
        
            // Отменяем стандартное поведение прокрутки страницы при касании элемента
            event.preventDefault();
            // Сохраняем текущее положение пальца при движении
            touchMoveX = event.touches[0].pageX;
            // Вычисляем расстояние, на которое нужно прокрутить контейнер
            const distance = touchStartX - touchMoveX;
            lastTouchDeltaX = distance;
            // Прокручиваем контейнер на расстояние
            touchStartX = touchMoveX;
            // Сохраняем текущую позицию как начальную для следующего шага
            container.current.scrollLeft += (distance * 1.5);
    };

    const handleTouchEnd = (event) => {
        clearInterval(int)
        // Отменяем стандартное поведение прокрутки страницы при касании элемента
        // event.preventDefault();
        // Сохраняем текущее положение пальца при движении
        if (lastTouchDeltaX < 0) {
            let i = lastTouchDeltaX;
            int = setInterval(function() {
                container.current.scrollLeft += i
                i += 0.5;
                if (i >= 0) clearInterval(int);
            }, 20);   
        }
        else {
            let i = lastTouchDeltaX;
            int = setInterval(function() {
                container.current.scrollLeft += i
                i -= 0.5;
                if (i <= 0) clearInterval(int);
            }, 20);

        }
    };

    // Обработчик события прокрутки колесика мыши
    const handleWheelScroll = (event) => {
        if (Math.abs(event.deltaX) > 0) {
            event.preventDefault();
            container.current.scrollLeft += event.deltaX;
        } else {
            container.current.style.scrollBehavior = "smooth"
            // Отменяем стандартное поведение прокрутки страницы
            event.preventDefault();
            // Изменяем горизонтальное положение контейнера на основе прокрутки колесика мыши
            container.current.scrollLeft += event.deltaY * 3;
            container.current.style.scrollBehavior = "auto"
        }
    }

    useEffect(() => {
        container.current.addEventListener("wheel", handleWheelScroll);
        container.current.addEventListener("touchstart", handleTouchStart);
        container.current.addEventListener("touchend", handleTouchEnd);
        container.current.addEventListener("touchmove", handleTouchMove);

    }, [])

    return (
        <div style={{overflow: "hidden"}}>
            <Preview/>
            <AboutUs/>
            <div className={st.fullContainer}>
                <div className={st.container}>
                    <span className={st.otherProjectsTitle}>Наши проекты</span>
                    <div className={st.swipe}>
                        <svg className={st.iconSwipe} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
                            <path className={st.right} d="M30,3.5,26.56,7l-.81-.82,2.06-2.1H22V2.92h5.81L25.74.82,26.55,0Z"/>
                            <path className={st.left} d="M8,4.08H2.19l2.06,2.1L3.44,7,0,3.5,3.45,0l.81.82L2.19,2.92H8Z"/>
                            <path className={st.hand}
                                  d="M25.41,11.18l-5.08-3A2.32,2.32,0,0,0,19,7.74a2.37,2.37,0,0,0-1.72.75v-5a2.46,2.46,0,0,0-.71-1.74A2.38,2.38,0,0,0,14.84,1h0a2.44,2.44,0,0,0-2.41,2.46V14.6l-.69-.7a4.7,4.7,0,0,0-3.36-1.42A4.75,4.75,0,0,0,6.32,13a1.26,1.26,0,0,0-.69.92A1.32,1.32,0,0,0,6,15l7.69,7.83A7.59,7.59,0,0,0,19,25a7.73,7.73,0,0,0,7.65-7.79V13.29A2.42,2.42,0,0,0,25.41,11.18Zm0,6A6.46,6.46,0,0,1,19,23.73h0a6.31,6.31,0,0,1-4.41-1.8L6.87,14.1a3.37,3.37,0,0,1,1.5-.35h0a3.42,3.42,0,0,1,2.47,1.05l1.53,1.55a.52.52,0,0,0,.36.16l.19,0a1.18,1.18,0,0,0,.75-1.1V3.46a1.17,1.17,0,0,1,1.16-1.19h0A1.18,1.18,0,0,1,16,3.46v6.91a.6.6,0,0,0,.39.56l.2,0a.58.58,0,0,0,.45-.22l1-1.3A1.16,1.16,0,0,1,19,9a1.12,1.12,0,0,1,.68.23l5.12,3a1.18,1.18,0,0,1,.59,1Z"/>
                        </svg>
                    </div>
                    <div className={st.otherProjects} ref={container}>
                        {(!otherProjectsInfo.length ? [...Array(3)] : otherProjectsInfo).map((project, id) =>
                            !otherProjectsInfo.length ? (
                                <MiniProject isLoading={true} key={id}/>
                            ) : (
                                <MiniProject isLoading={false} project={project} key={project.id} width={10}/>
                            )
                        )}
                    </div>
                    <div className={st.buttonBlock}>
                        <Link to={`/projects`} className={st.linkToAllProjects}>
                            <button className={st.buttonAllProjects}>Все проекты</button>
                        </Link>
                    </div>

                </div>
            </div>
            <HowWeWork/>

        </div>
    );
};

