import React from 'react';
import st from "./AboutCompany.module.css";
import data from './data.json';
import {Link} from "react-router-dom";
import {ReactComponent as Arrow} from "../../image/icons/arrow-right.svg";
import cn from "classnames";

const AboutCompany = () => {

    return (
        <div className={st.fullContainer}>
            <div className={st.container}>
                <div className={st.navigation}>
                    <Link to={"/home"}>Главная</Link>
                    <Arrow/>
                    <span>Документы</span>
                </div>
                <div className={st.infoContainer}>
                    <div className={st.navigationBlock}>
                        {data.infoBlocks.map(info => (
                            <>
                                <span className={st.navigationTitle}> • {info.infoTitle}</span>
                                {info.infoContent.map(content => (
                                    <a href={"#" + content.id}  className={st.navigationContentTitle}> • {content.title}</a>
                                    ))
                                }
                            </>
                        ))}
                    </div>
                    <div>
                        {data.infoBlocks.map(info => (
                            <div className={st.infoBlock}>
                                <span className={st.mainTitle}>{info.infoTitle}</span>
                                {info.infoContent.map(content => (
                                    <>
                                        <div className={st.infoMargin} id={content.id}></div>
                                        <div className={st.info}>
                                            <span className={st.contentTitle}>{content.title}</span>
                                            {content.text.split("\n").map((line, index) => (
                                                <span key={index} className={st.contentText}>{line}</span>
                                            ))}
                                        </div>
                                    </>
                                ))
                                }
                            </div>
                        ))}
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default AboutCompany;