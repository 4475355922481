import React, {useEffect, useState} from 'react';
import st from "./MainSlider.module.css";
import cn from "classnames";
import photo1 from "../../../image/previewSlider/image1.png";
import photo2 from "../../../image/previewSlider/image2.jpg";
import photo3 from "../../../image/previewSlider/image3.jpg";
import photo4 from "../../../image/previewSlider/image4.JPG";
import photo6 from "../../../image/previewSlider/image6.png";
import photo8 from "../../../image/previewSlider/image8_2.JPG";
import photo9 from "../../../image/previewSlider/image9.JPG";

const images = [
    <img className={st.photo} src={photo9} alt={"sliderPhoto"}/>,
    <img className={st.photo} src={photo1} alt={"sliderPhoto"}/>,
    <img className={st.photo} src={photo2} alt={"sliderPhoto"}/>,
    <img className={st.photo} src={photo3} alt={"sliderPhoto"}/>,
    <img className={st.photo} src={photo4} alt={"sliderPhoto"}/>,
    <img className={st.photo} src={photo6} alt={"sliderPhoto"}/>,
    <img className={st.photo} src={photo8} alt={"sliderPhoto"}/>,
]

// firstSlide from "../../../image/previewSlider"

const MainSlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((current) => {
                return current === images.length - 1 ? 0 : current + 1
            });
        }, 5000)
        return () => clearInterval()
    }, [])
    const prevImageIndex = activeIndex ? activeIndex - 1 : images.length - 1;
    const nextImageIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    
    
    return (
        <div className={st.sliderBlock}>
            <div className={cn(st.photoBlock, st.photoBlockPrev)} key={prevImageIndex}>
                {images[prevImageIndex]}
            </div>
            <div className={st.photoBlock} key={activeIndex}>
                {images[activeIndex]}
            </div>
            <div className={cn(st.photoBlock, st.photoBlockNext)} key={nextImageIndex}>
                {images[nextImageIndex]}
            </div>
        </div>
    );
};

export default MainSlider;