import st from "./HowWeWork.module.css"
import React, {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import cn from "classnames";
import {ReactComponent as U} from "../../image/icons/howWeWork/path58.svg";
import {ReactComponent as UDark} from "../../image/icons/howWeWork/path58Dark.svg";
import {ReactComponent as GreenLines} from "../../image/icons/howWeWork/greenLinesDark.svg";
import {ReactComponent as BlueLines} from "../../image/icons/howWeWork/blueLinesDark.svg";
import {ReactComponent as Star} from "../../image/icons/howWeWork/path50.svg";
import {ReactComponent as StarDark} from "../../image/icons/howWeWork/path50Dark.svg";
import {ReactComponent as El3} from "../../image/icons/howWeWork/ellipse19.svg";
import {ReactComponent as K} from "../../image/icons/howWeWork/path62.svg";
import {ReactComponent as Pila} from "../../image/icons/howWeWork/pila.svg";
import {ReactComponent as Datchic} from "../../image/icons/howWeWork/datchic.svg";
import {ReactComponent as Lazer} from "../../image/icons/howWeWork/lazer.svg";
import {ReactComponent as Drel} from "../../image/icons/howWeWork/drel.svg";
import {ReactComponent as Ellipse1Blue} from "../../image/icons/howWeWork/timeline1/ellipse1.svg";
import {ReactComponent as Ellipse1Green} from "../../image/icons/howWeWork/timeline2/ellipse1.svg";
import {ReactComponent as Ellipse2Blue} from "../../image/icons/howWeWork/timeline1/ellipse2.svg";
import {ReactComponent as Ellipse2Green} from "../../image/icons/howWeWork/timeline2/ellipse2.svg";
import {ReactComponent as Union} from "../../image/icons/howWeWork/timeline1/Union.svg";
import {ReactComponent as UnionRev} from "../../image/icons/howWeWork/timeline2/UnionRev.svg";
import {ReactComponent as Calc} from "../../image/icons/howWeWork/calc.svg";
import {ReactComponent as Agreement} from "../../image/icons/howWeWork/agreement.svg";
import {ReactComponent as Tool} from "../../image/icons/howWeWork/tool.svg";
import {ReactComponent as House} from "../../image/icons/howWeWork/house.svg";
import {ReactComponent as ArrowDown1} from "../../image/icons/howWeWork/arrowDown1.svg";
import {ReactComponent as ArrowDown2} from "../../image/icons/howWeWork/arrowDown2.svg";
import {ReactComponent as LineDown1} from "../../image/icons/howWeWork/timeLineDark1/timeLine.svg";
import {ReactComponent as LineDown2} from "../../image/icons/howWeWork/timeLineDark1/timeLine2.svg";
import { HashLink as Link } from 'react-router-hash-link';


const HowWeWork = () => {

    const {ref: myRef, inView: isView} = useInView();
    const {ref: stepTitleRef1, inView: isView1} = useInView();
    const {ref: stepTitleRef2, inView: isView2} = useInView();
    const {ref: stepTitleRef3, inView: isView3} = useInView();
    const {ref: stepTitleRef4, inView: isView4} = useInView();

    const [hoveredTitle, setHoveredTitle] = useState(false);
    const [hoveredStepTitle1, setHoveredStepTitle1] = useState(false);
    const [hoveredStepTitle2, setHoveredStepTitle2] = useState(false);
    const [hoveredStepTitle3, setHoveredStepTitle3] = useState(false);
    const [hoveredStepTitle4, setHoveredStepTitle4] = useState(false);

    const text = [
        {
            link: "estimate",
            title: "1 ЭТАП: СМЕТА",
            text: "Специалисты по сметам учитывают все необходимые затраты, такие как материалы, оборудование, трудовые ресурсы, транспортные расходы и другие факторы. Они также учитывают текущие рыночные цены и стоимость услуг, чтобы предоставить точные и реалистичные прогнозы расходов на проект. Предварительный ориентир предоставляется по стоимости всех работ и материалов на основании проекта.\n" +
                "В результате этапа сметы, клиент получает детальный отчет о стоимости проекта, который служит основой для принятия решений и дальнейшего планирования."
        },
        {
            link: "contract",
            title: "2 ЭТАП: ДОГОВОР",
            text: "На этом этапе наша команда и клиент заключают юридический договор, который определяет условия и обязательства сторон.\n" +
                "В договоре прописываются детали проекта, включая объем работ, планы, спецификации и ожидаемый результат. Также в договоре определяется стоимость проекта, условия платежей, сроки выполнения работ и гарантийные обязательства со стороны нашей команды. Обязательными условиями в заключении договора являются наличие утвержденного рабочего проекта и страхование гражданской ответственности перед третьими лицами на время проведения строительно-ремонтных работ."
        },
        {
            link: "renovation",
            title: "3 ЭТАП: РЕМОНТ",
            text: "На этом этапе происходит осуществление ремонтных, строительных и отделочных работ. Команда профессионалов  приступает к физическому выполнению проекта.\n" +
                "Мы обеспечиваем прозрачность и регулярное информирование о ходе работ, чтобы удовлетворить ожидания клиента. Весь процесс ремонта выполняется с использованием качественных материалов и с применением современного оборудования, чтобы достичь оптимальных результатов."
        },
        {
            link: "delivery",
            title: "4 ЭТАП: СДАЧА РАБОТЫ",
            text: "На этом этапе клиент получает готовый проект от нашей команды. Проводится осмотр и проверка выполненных работ. Клиент ознакамливается с результатами и, при необходимости, производятся корректировки. Завершение этапа - окончательная сдача проекта клиенту."
        }
    ]
    
    useEffect(() => {
        if (isView)
            setHoveredTitle(true)
        if (isView1)
            setHoveredStepTitle1(true)
        if (isView2)
            setHoveredStepTitle2(true)
        if (isView3)
            setHoveredStepTitle3(true)
        if (isView4)
            setHoveredStepTitle4(true)
    }, [isView, isView1, isView2, isView3, isView4])

    return (
        <div className={st.fullContainer}>
            <div className={st.container}>
                <U className={st.u}/>
                <UDark className={st.uDark}/>
                <Star className={st.star}/>
                <div className={st.darkGreenLinesBlurredCircle}></div>
                <GreenLines className={st.greenLines}/>
                <El3 className={st.el3}/>
                <BlueLines className={st.blueLines}/>
                <K className={st.k}/>
                <StarDark className={st.starDark}/>

                <span ref={myRef} className={cn(st.title, {[st.titleHovered]: hoveredTitle})}>КАК МЫ РАБОТАЕМ</span>

                <div className={st.glassBlock}>
                    <Pila className={st.pila}/>
                    <Datchic className={st.datchic}/>
                    <Lazer className={st.lazer}/>
                    <Drel className={st.drel}/>
                    <div className={st.contentRows}>
                        <div className={st.contentRow}>
                            <div className={st.contentBlock}>

                                <div className={st.timeLineBlock}>
                                    <Ellipse1Blue className={st.el1}/>
                                    <Union className={st.union}/>
                                    <div className={st.el2Block}>
                                        <Ellipse2Blue className={st.el2}/>
                                        <Calc className={st.iconInElep} style={{width: "65%", height: "65%"}}/>
                                    </div>
                                </div>

                                <div className={st.content}>
                                    <ArrowDown1 className={st.arrowDown}/>

                                    <div className={st.timeLineDarkBlock}>
                                        <div className={cn(st.darkCircleBlurredBlock, {[st.darkCircleBlurredBlockHovered]: hoveredStepTitle1})}>
                                            <Calc className={st.iconInBlu1}/>
                                            <div className={st.darkCircleBlurredBlue}></div>
                                        </div>
                                        <LineDown1 className={st.lineDown}/>
                                    </div>

                                    <div className={st.textContent}>
                                    <span ref={stepTitleRef1}
                                          className={cn(st.stepTitleRight, {[st.stepTitleHovered]: hoveredStepTitle1})}>{text[0].title}</span>
                                        {text[0].text.split("\n").map((line, index) => (
                                            <span key={index} className={cn(st.stepText, {[st.stepTextHovered]: hoveredStepTitle1})}>{line}</span>
                                        ))}
                                        <Link to={`/about-company#${text[0].link}`}><span className={cn(st.stepTextLink, {[st.stepTextHovered]: hoveredStepTitle1})}>Подробнее >></span></Link>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={st.contentRow}>
                            <div className={st.contentBlock}>
                                <div className={st.timeLineBlock}>
                                    <div className={st.el2Block}>
                                        <Ellipse2Green className={st.el2}/>
                                        <Agreement className={st.iconInElep} style={{left: 'calc(50% + 20px)'}}/>
                                    </div>
                                    <UnionRev className={st.union}/>
                                    <Ellipse1Green className={st.el1}/>
                                </div>

                                <div className={st.content}>
                                    <div className={st.textContent}>
                                        <span ref={stepTitleRef2}
                                              className={cn(st.stepTitleLeft, {[st.stepTitleHovered]: hoveredStepTitle2})}>{text[1].title}</span>
                                        {text[1].text.split("\n").map((line, index) => (
                                            <span key={index} className={cn(st.stepText, {[st.stepTextHovered]: hoveredStepTitle2})}>{line}</span>
                                        ))}
                                        <Link to={`/about-company#${text[1].link}`}><span className={cn(st.stepTextLink, {[st.stepTextHovered]: hoveredStepTitle2})}>Подробнее >></span></Link>

                                    </div>
                                    <ArrowDown2 className={st.arrowDown}/>

                                    <div className={st.timeLineDarkBlock}>
                                        <div className={cn(st.darkCircleBlurredBlock, {[st.darkCircleBlurredBlockHovered]: hoveredStepTitle2})}>
                                            <Agreement className={st.iconInBlu2} style={{width: 40, height: 40}}/>
                                            <div className={st.darkCircleBlurredGreen}></div>
                                        </div>
                                        <LineDown2 className={st.lineDown}/>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className={st.contentRow}>
                            <div className={st.contentBlock}>

                                <div className={st.timeLineBlock}>
                                    <Ellipse1Blue className={st.el1}/>
                                    <Union className={st.union}/>
                                    <div className={st.el2Block}>
                                        <Ellipse2Blue className={st.el2}/>
                                        <Tool className={st.iconInElep}/>
                                    </div>
                                </div>

                                <div className={st.content}>
                                    <ArrowDown1 className={st.arrowDown}/>

                                    <div className={st.timeLineDarkBlock}>
                                        <div className={cn(st.darkCircleBlurredBlock, {[st.darkCircleBlurredBlockHovered]: hoveredStepTitle3})}>
                                            <Tool className={st.iconInBlu1} style={{width: 45, height: 45}}/>
                                            <div className={st.darkCircleBlurredBlue}></div>
                                        </div>
                                        <LineDown1 className={st.lineDown}/>
                                    </div>

                                    <div className={st.textContent}>
                                        <span ref={stepTitleRef3}
                                              className={cn(st.stepTitleRight, {[st.stepTitleHovered]: hoveredStepTitle3})}>{text[2].title}</span>
                                        {text[2].text.split("\n").map((line, index) => (
                                            <span key={index} className={cn(st.stepText, {[st.stepTextHovered]: hoveredStepTitle3})}>{line}</span>
                                        ))}
                                        <Link to={`/about-company#${text[2].link}`}><span className={cn(st.stepTextLink, {[st.stepTextHovered]: hoveredStepTitle3})}>Подробнее >></span></Link>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={st.contentRow}>
                            <div className={st.contentBlock}>
                                <div className={st.timeLineBlock}>
                                    <div className={st.el2Block}>
                                        <Ellipse2Green className={st.el2}/>
                                        <Calc className={st.iconInElep} style={{width: "65%", height: "65%"}}/>
                                    </div>
                                    <UnionRev className={st.union}/>
                                    <Ellipse1Green className={st.el1}/>
                                </div>
                                <div className={st.content}>
                                    <div className={st.textContent}>
                                    <span ref={stepTitleRef4}
                                          className={cn(st.stepTitleLeft, {[st.stepTitleHovered]: hoveredStepTitle4})}>{text[3].title}</span>
                                        {text[3].text.split("\n").map((line, index) => (
                                            <span key={index} className={cn(st.stepText, {[st.stepTextHovered]: hoveredStepTitle4})}>{line}</span>
                                        ))}
                                        <Link to={`/about-company#${text[3].link}`}><span className={cn(st.stepTextLink, {[st.stepTextHovered]: hoveredStepTitle4})}>Подробнее >></span></Link>

                                    </div>
                                    <ArrowDown2 className={st.arrowDown}/>

                                    <div className={st.timeLineDarkBlock}>
                                        <div className={cn(st.darkCircleBlurredBlock, {[st.darkCircleBlurredBlockHovered]: hoveredStepTitle4})}>
                                            <House className={st.iconInBlu1} style={{width: 45, height: 45}}/>
                                            <div className={st.darkCircleBlurredGreen}></div>
                                        </div>
                                        <LineDown2 className={st.lineDown}/>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )

};

export default HowWeWork;