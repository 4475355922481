import React from 'react';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import st from './ProjectSkeleton.module.css';

const ProjectSkeleton = () => {
    return (
        <div className={st.skeletonBlock}>
            {/*<div className={st.skeleton}>*/}
                <Skeleton className={st.skeleton}/>

            {/*</div>*/}

        </div>
    );
};

export default ProjectSkeleton;