import React, {useEffect, useRef} from 'react';
import st from "../../AboutUs/AboutUs.module.css";
import cn from "classnames";

const MainLogoIcon = ({ hovered, setHovered }) => {


    return (
        <svg className={st.logoBlock} viewBox="0 0 728 640" fill="none" xmlns="http://www.w3.org/2000/svg">
            <svg className={cn(st.logo, {[st.logoHovered]: hovered})} onMouseEnter={() => setHovered(true)} >
                <rect x="303" y="258" width="125" height="125" fill="#D9D9D9" fillOpacity="0"/>
                <path
                    d="M364.017 304.778C360.596 311.241 355.377 316.571 349.04 320.071C355.377 323.571 360.596 328.901 364.017 335.365C367.438 328.901 372.657 323.571 378.993 320.071C372.657 316.571 367.438 311.241 364.017 304.778Z"/>
                <path
                    d="M343.849 293.546C343.724 293.817 343.583 294.072 343.411 294.328C342.205 296.353 340.498 298.043 338.477 299.239C336.41 300.468 334.013 301.186 331.428 301.186H331.397C328.828 301.186 326.432 300.484 324.364 299.255C322.297 298.059 320.574 296.337 319.353 294.279C319.227 294.072 319.117 293.88 319.008 293.673C317.912 291.632 317.3 289.303 317.3 286.831C317.3 281.52 320.136 276.879 324.364 274.406C326.432 273.179 328.828 272.476 331.397 272.476H331.428C334.013 272.476 336.41 273.195 338.477 274.422C342.675 276.911 345.494 281.536 345.494 286.831C345.494 289.255 344.899 291.536 343.849 293.546ZM355.816 272.476L355.754 272.365C353.702 268.793 350.929 265.73 347.624 263.37C346.951 262.859 346.246 262.413 345.525 261.998C341.391 259.542 336.535 258.138 331.397 258.122C326.26 258.122 321.434 259.542 317.3 261.982C313.197 264.375 309.75 267.819 307.276 271.951C307.182 272.126 307.072 272.301 306.979 272.476C306.916 272.588 306.853 272.7 306.791 272.812C306.728 272.923 306.665 273.035 306.602 273.146C304.441 277.214 303.203 281.871 303.203 286.831V315.54H317.3V311.68C321.434 314.12 326.26 315.54 331.397 315.54C336.503 315.524 341.391 314.12 345.525 311.664C346.246 311.249 346.951 310.803 347.624 310.292C350.961 307.916 353.765 304.806 355.816 301.186C355.832 301.17 355.832 301.153 355.848 301.137C358.229 296.927 359.591 292.047 359.591 286.831C359.591 281.599 358.229 276.703 355.816 272.476Z"/>
                <path
                    d="M421.398 273.147C421.273 272.924 421.132 272.685 421.007 272.477C419.738 270.212 418.156 268.154 416.338 266.336C414.663 264.646 412.752 263.178 410.701 261.982C410.701 261.982 410.701 261.966 410.685 261.966C408.648 260.77 406.455 259.813 404.138 259.175L400.66 265.268L396.572 272.429L396.541 272.477L392.468 279.606L388.348 286.831L384.339 293.881H384.323C384.292 293.817 384.245 293.737 384.214 293.673C384.183 293.626 384.166 293.593 384.151 293.545C383.102 291.536 382.507 289.255 382.507 286.831C382.507 284.662 382.992 282.588 383.838 280.739C384.183 280.005 384.574 279.319 385.013 278.665L388.474 272.572L388.521 272.477L396.572 258.282V258.123C391.435 258.123 386.626 259.542 382.475 261.998C381.755 262.412 381.05 262.859 380.376 263.37C377.072 265.73 374.283 268.808 372.247 272.381L372.2 272.477C369.788 276.703 368.41 281.6 368.41 286.831C368.41 292.062 369.788 296.959 372.2 301.185L372.231 301.233C374.252 304.822 377.056 307.932 380.376 310.293C381.05 310.802 381.755 311.249 382.475 311.664C384.527 312.876 386.72 313.833 389.053 314.487L392.5 308.41L396.572 301.217L400.66 294.024L404.732 286.831L408.805 279.654C410.011 281.76 410.701 284.215 410.701 286.831C410.701 289.303 410.089 291.632 408.993 293.673C408.883 293.881 408.773 294.072 408.648 294.279L404.732 301.185L396.572 315.54H396.604C401.741 315.54 406.549 314.136 410.685 311.696C414.96 309.192 418.579 305.507 421.038 301.154C421.164 300.946 421.273 300.739 421.398 300.516C423.559 296.448 424.797 291.791 424.797 286.831C424.797 281.87 423.559 277.213 421.398 273.147Z"/>
                <path
                    d="M421.398 339.607C421.273 339.384 421.132 339.145 421.007 338.937C421.007 338.953 421.007 338.953 421.007 338.937C418.547 334.583 414.976 330.947 410.7 328.427L404.732 338.937L403.636 340.851C405.688 342.064 407.427 343.786 408.648 345.859C408.773 346.05 408.883 346.242 408.993 346.449C410.089 348.491 410.7 350.803 410.7 353.292C410.7 358.619 407.865 363.26 403.636 365.732C401.568 366.944 399.124 367.646 396.572 367.646C394.003 367.646 391.591 366.928 389.523 365.7L388.427 367.646L382.46 378.124C386.61 380.581 391.466 381.984 396.604 382C401.741 382 406.549 380.613 410.7 378.157C414.976 375.637 418.563 371.984 421.022 367.63C421.147 367.422 421.273 367.199 421.398 366.976C423.559 362.909 424.797 358.251 424.797 353.292C424.797 348.331 423.559 343.674 421.398 339.607Z"/>
                <path
                    d="M355.691 367.646L347.672 353.371L347.624 353.467V353.292L343.849 346.576C343.787 346.433 343.708 346.29 343.614 346.162L347.624 339.113L347.718 338.937L351.18 332.845C350.083 331.728 348.893 330.723 347.624 329.814C346.951 329.32 346.262 328.873 345.541 328.458C343.49 327.231 341.265 326.274 338.916 325.635L335.485 331.68L331.428 338.826L331.366 338.937L327.324 346.067L323.221 353.292L319.18 360.421C317.974 358.331 317.301 355.891 317.301 353.292V352.914C317.301 350.643 317.89 348.413 319.008 346.449C319.117 346.242 319.227 346.05 319.353 345.859L323.268 338.937H323.283L331.428 324.598V324.583H331.397C326.259 324.583 321.451 325.971 317.301 328.427C313.197 330.835 309.751 334.28 307.276 338.411C307.182 338.587 307.072 338.762 306.978 338.937C306.978 338.921 306.962 338.937 306.978 338.937C306.854 339.145 306.712 339.384 306.603 339.607C304.441 343.674 303.203 348.331 303.203 353.292C303.203 358.251 304.441 362.909 306.603 366.976C306.665 367.088 306.727 367.199 306.79 367.311C306.853 367.423 306.915 367.534 306.978 367.63V367.646C307.072 367.821 307.182 367.997 307.276 368.172C308.497 370.213 309.955 372.08 311.615 373.738C313.306 375.461 315.217 376.944 317.301 378.157C319.336 379.368 321.53 380.309 323.847 380.947L327.324 374.823L331.397 367.646H331.413L331.428 367.614L335.501 360.452L338.477 365.7L339.574 367.646H339.589L345.541 378.124C346.262 377.71 346.951 377.263 347.624 376.768C350.929 374.392 353.702 371.33 355.754 367.758L355.691 367.646Z"/>
                <path
                    d="M396.572 353.292H380.282L372.169 338.985C372.169 338.969 372.169 338.953 372.2 338.937C374.236 335.317 377.04 332.206 380.376 329.815C381.05 329.32 381.738 328.873 382.46 328.459L388.427 338.953L389.523 340.883L396.572 353.292Z"/>
            </svg>
            <svg className={st.logoLines}>
                <path className={cn(st.backLine, {[st.backLineHovered]: hovered})} d="M446 224.5C553 2 544.5 2 614 2C655.5 2 671.5 2 726 2"
                      strokeWidth="3" strokeLinecap="round"/>
                <path className={cn(st.line , {[st.hovered]: hovered})} d="M445 224.5C553 2 544.5 2 614 2C655.5 2 671.5 2 726 2"
                      strokeWidth="3" strokeLinecap="round"/>

                <path className={cn(st.backLine, {[st.backLineHovered]: hovered})} d="M283 225C175 2.49988 183.5 2.49988 114 2.49988C72.5 2.49988 56.5 2.49988 2 2.49988"
                      strokeWidth="3" strokeLinecap="round"/>
                <path className={cn(st.line , {[st.hovered]: hovered})} d="M283 225C175 2.49988 183.5 2.49988 114 2.49988C72.5 2.49988 56.5 2.49988 2 2.49988"
                      strokeWidth="3" strokeLinecap="round"/>

                <path className={cn(st.backLine, {[st.backLineHovered]: hovered})} d="M445 415.5C553 638 544.5 638 614 638C655.5 638 671.5 638 726 638"
                      strokeWidth="3" strokeLinecap="round"/>
                <path className={cn(st.line , {[st.hovered]: hovered})} d="M445 415.5C553 638 544.5 638 614 638C655.5 638 671.5 638 726 638" stroke="#A7BCDB"
                      strokeWidth="3" strokeLinecap="round"/>

                <path className={cn(st.backLine, {[st.backLineHovered]: hovered})} d="M283 415C175 637.5 183.5 637.5 114 637.5C72.5 637.5 56.5 637.5 2 637.5"
                      strokeWidth="3" strokeLinecap="round"/>
                <path className={cn(st.line , {[st.hovered]: hovered})} d="M283 415C175 637.5 183.5 637.5 114 637.5C72.5 637.5 56.5 637.5 2 637.5"
                      strokeWidth="3" strokeLinecap="round"/>
            </svg>

        </svg>
    );
};

export default MainLogoIcon;