import st from "./Slider.module.css";
import {ReactComponent as ImageLogo} from "../../image/icons/imageLogo.svg";
import {ReactComponent as ArrowLeft} from "../../image/icons/chevron-left.svg";
import {ReactComponent as ArrowRight} from "../../image/icons/chevron-right.svg";
import {ReactComponent as Close} from "../../image/icons/close.svg";
import cn from "classnames";
import React, {useEffect, useRef, useState} from "react";
import axios from "../../axios";
import Loader from "../UI/Loader/Loader";
import {useSwipeable} from "react-swipeable";
import Logo from "../../image/logo";

const Slider = ({project}) => {

    // текущее фото на слайдере (его base64 представление)

    // текущая позиция ленты с блоками
    const [position, setPosition] = useState(0);

    //  словарь с фотографиями (словарь, чтобы хранить все фотки в нужном порядке)
    const [photoUrl, setPhotoUrl] = useState({})

    //  словарь с фотографиями плохого качества (словарь, чтобы хранить все фотки в нужном порядке)
    const [photoUrlLow, setPhotoUrlLow] = useState({})

    // индекс текущей фотографии
    const [currentImg, setCurrentImg] = useState(0);

    const [isOpenedPhoto, setIsOpenedPhoto] = useState(false);

    // нужно, чтобы один раз загрузить
    // не использовал useEffect тк он ругается на отсутствие зависимостей
    // здесь мы получаем данные о всем проекте и загружаем фотографии
    // const [load, setLoad] = useState(true);
    useEffect(() => {
        const controller = new AbortController();

        // setLoad(false)
        const fetchPhoto = async () => {
            if (project && project.photos) {
                for (let i = 0; i < project.photos.length; i++) {
                    axios.get(`/photos/${project.photos[i].id}`, {
                        // устанавливаем связь с контроллером
                        signal: controller.signal,
                        responseType: 'blob'
                    }).then((response) => {

                        // Создаем временный URL из объекта Blob
                        photoUrl[project.photos[i].position - 1] = URL.createObjectURL(response.data);
                        photoUrlLow[project.photos[i].position - 1] = false
                        // Обновляем состояние с новым массивом фотографий
                        setPhotoUrl({ ...photoUrl});
                        setPhotoUrlLow({ ...photoUrlLow});
                    });
                }
            }
        }
        fetchPhoto();
        return () => {
            controller.abort();
        };
    }, [])
    const canvas = document.createElement("canvas");
    const resizeImg = async (img, id) => {
        if (photoUrlLow[id]) {
            return
        }
        photoUrlLow[id] = true
        setTimeout(() => {
            setPhotoUrlLow({...photoUrlLow})
            // if (img.target.height < 80) return
            const [newWidth, newHeight] = calculateSize(img.target, 180, 180);
            // console.log(newWidth, newHeight)
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img.target, 0, 0, newWidth, newHeight);
            img.target.src = canvas.toDataURL()
            // setPhotoUrl({...photoUrl})
        }, id * 300);
    }
    
    const calculateSize = (img, maxWidth, maxHeight) => {
        let width = img.width;
        let height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    }

    useEffect(() => {
        if (isOpenedPhoto) {
            document.body.style.position = 'fixed';
            document.body.style.width = `100%`;
        } else {
            document.body.style.position = 'static';
        }
    }, [isOpenedPhoto])

    // данный блок кода привязан к значению margin-right в адаптиве верстки
    const getMarginRight = () => {
        if (window.screen.width <= 500) {
            return 12;
        } else if (window.screen.width <= 730) {
            return 15;
        } else if (window.screen.width <= 1040) {
            return 18;
        } else {
            return 20;
        }
    }

    const getElements = () => {

        // получили ширину блока с миниФото
        const parentWidth = document.getElementById('sliderBottom').offsetWidth;
        // получили позицию блока с миниФото оносительно контейнера (слева)
        const parentLeft = document.getElementById('sliderBottom').offsetLeft;


        // получили ширину текущего выбранного элемента + прибавили его marginRight
        const photoWidth = document.getElementById('sliderBottom').children[currentImg].offsetWidth + getMarginRight();
        //  расстояние текущего блока относительно начала (слева) род блока
        const photoLeft = document.getElementById('sliderBottom').children[currentImg].offsetLeft;


        // получили ширину контейнера видимой части фото
        const containerWidth = document.getElementById('sliderBottomBlock').offsetWidth;

        return {parentWidth, parentLeft, photoWidth, photoLeft, containerWidth};
    }

    // при нажатии на какое-то фото
    const updateCurrentImg = (e) => {
        const mr = getMarginRight();

        // получили ширину текущего выбранного элемента + прибавили его marginRight
        const photoWidth = e.target.parentElement.getBoundingClientRect().width + mr;
        //  расстояние текущего блока относительно начала (слева) род блока
        const photoLeft = e.target.parentElement.offsetLeft;

        // получили ширину блока с миниФото
        const parentWidth = e.target.parentElement.parentElement.getBoundingClientRect().width;
        // получили позицию блока с миниФото оносительно контейнера (слева)
        const parentLeft = e.target.parentElement.parentElement.offsetLeft;

        // получили ширину контейнера видимой части фото
        const containerWidth = e.target.parentElement.parentElement.parentElement.getBoundingClientRect().width;


        // console.log("photoWidth " + photoWidth)
        // console.log("photoLeft " + photoLeft)
        // console.log("parentWidth " + parentWidth)
        // console.log("parentLeft " + parentLeft)
        // console.log("containerWidth " + containerWidth)
        // console.log(e.target)
        // console.log("==========")

        // если расстояние левой границы нажатого блока до левой границы контейнера меньше его 3/10 ширины, то
        if (photoLeft + parentLeft < 0.3 * containerWidth) {
            // если после смещения род блок будет на расстоянии от левой границы контейнера меньше 0 (будет его полностью заполнять), то смещаем
            if (position + 0.3 * containerWidth - (photoLeft + parentLeft) < 0)
                setPosition(position + 0.3 * containerWidth - (photoLeft + parentLeft))
            else
                setPosition(0);
        }

        // если расстояние правой границы нажатого блока до левой границы контейнера больше его 7/10 ширины, то
        if (photoLeft + parentLeft + photoWidth > 0.7 * containerWidth) {
            // если после смещения род блок будет на расстоянии от левой границы контейнера больше макс допустимого значения смещения влево (будет его полностью заполнять), то смещаем
            if (position - (photoLeft + parentLeft + photoWidth - 0.7 * containerWidth) > containerWidth - parentWidth)
                setPosition(position - (photoLeft + parentLeft + photoWidth - 0.7 * containerWidth));
            else
                setPosition(containerWidth - parentWidth);
        }

        setCurrentImg(parseInt(e.target.id));
    }
    
    // функция для стрелок
    const changeCurrentImgByArrow = (e) => {
        e.stopPropagation();
        console.log(e.target.parentElement.parentElement.children[1].children[0])
        // данный блок кода привязан к значению margin-right в адптиве верстки
        const mr = getMarginRight();

        let childrenIndex = isOpenedPhoto ? 2 : 1;
        
        // получили ширину текущего выбранного элемента + прибавили его marginRight
        const photoWidth = e.target.parentElement.parentElement.children[childrenIndex].children[0].children[currentImg].offsetWidth + mr;
        //  расстояние текущего блока относительно начала (слева) род блока
        const photoLeft = e.target.parentElement.parentElement.children[childrenIndex].children[0].children[currentImg].offsetLeft;

        // получили ширину блока с миниФото
        const parentWidth = e.target.parentElement.parentElement.children[childrenIndex].children[0].offsetWidth;
        // получили позицию блока с миниФото оносительно контейнера (слева)
        const parentLeft = e.target.parentElement.parentElement.children[childrenIndex].children[0].offsetLeft;

        // получили ширину контейнера видимой части фото
        const containerWidth = e.target.parentElement.parentElement.children[childrenIndex].offsetWidth;

        // console.log("photoWidth " + photoWidth)
        // console.log("photoLeft " + photoLeft)
        // console.log("parentWidth " + parentWidth)
        // console.log("parentLeft " + parentLeft)
        // console.log("containerWidth " + containerWidth)
        // console.log("==========")

        if (e.target.id === "arrowRight" && currentImg < project.photos.length - 1) {
            setCurrentImg(currentImg + 1);

            // если расстояние текущего блока (его правой границы) до левой границы контейнера больше, чем его 2/3 ширины, то начинаем смещать ленту влево
            if (photoLeft + parentLeft + photoWidth > 0.66 * containerWidth) {
                // если новая позиция(отриц число) больше, чем то, насколько можно сместить влево блок с минимФото, чтобы он полностью занимал контейнер
                if (position - photoWidth > containerWidth - parentWidth)
                    setPosition(position - photoWidth);
                // иначе ставим позицию, равную максимальному смещению влево, чтобы блок с минимфото полностью заполнял контейнер
                else setPosition(containerWidth - parentWidth);
            }

        } else if (e.target.id === "arrowLeft" && currentImg > 0) {
            setCurrentImg(currentImg - 1);

            // если расстояние левой границы текущего блока до левой границы контейнера меньше, чем его 1/3 ширины, то смещаем ленту вправо
            if (photoLeft + parentLeft < 0.33 * containerWidth) {
                // если слева есть место для смещение, меньшее
                if (position < -photoWidth) {
                    setPosition(position + photoWidth)
                } else {
                    setPosition(0);
                }
            }
        }

    }

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            if (currentImg < project.photos.length - 1) {
                // console.log("User Swiped left!")

                const {parentWidth, parentLeft, photoWidth, photoLeft, containerWidth} = getElements();

                setCurrentImg(currentImg + 1);

                // если расстояние текущего блока (его правой границы) до левой границы контейнера больше, чем его 2/3 ширины, то начинаем смещать ленту влево
                if (photoLeft + parentLeft + photoWidth > 0.66 * containerWidth) {
                    // если новая позиция(отриц число) больше, чем то, насколько можно сместить влево блок с минимФото, чтобы он полностью занимал контейнер
                    if (position - photoWidth > containerWidth - parentWidth)
                        setPosition(position - photoWidth);
                    // иначе ставим позицию, равную максимальному смещению влево, чтобы блок с минимфото полностью заполнял контейнер
                    else setPosition(containerWidth - parentWidth);
                }
            }
        },
        onSwipedRight: (eventData) => {
            if (currentImg > 0) {
                // console.log("User Swiped right!")

                const {parentLeft, photoWidth, photoLeft, containerWidth} = getElements();


                setCurrentImg(currentImg - 1);

                // если расстояние левой границы текущего блока до левой границы контейнера меньше, чем его 1/3 ширины, то смещаем ленту вправо
                if (photoLeft + parentLeft < 0.33 * containerWidth) {
                    // если слева есть место для смещение, меньшее
                    if (position < -photoWidth) {
                        setPosition(position + photoWidth)
                    } else {
                        setPosition(0);
                    }
                }
            }
        }
    });

    const enableScroll = () => {
        document.removeEventListener('wheel', this, false)
    }

    const disableScroll = () => {
        document.addEventListener('wheel', this, {
            passive: false,
        })
    }
    
    const openClosePhoto = () => {
        setIsOpenedPhoto(!isOpenedPhoto)
    }

    const photoClick = (e) => {
        if (isOpenedPhoto) {
            e.stopPropagation()
        } else {
            setIsOpenedPhoto(!isOpenedPhoto)
        }
    }
    
return (
        <>
            {/*<div className={cn({[st.shadow]: isOpenedPhoto})} onClick={() => setIsOpenedPhoto(false)}></div>*/}

            <div className={st.sliderBlock}>
                <div className={cn(st.currentPhotoBlock, {[st.currentPhotoBlockOpened]: isOpenedPhoto})} {...handlers} onClick={() => openClosePhoto()}>
                    {photoUrl[currentImg] ?
                        <img className={cn(st.currentPhoto, {[st.currentPhotoOpened]: isOpenedPhoto})} src={photoUrl[currentImg]} alt={"currentPhoto"} onClick={(e) => photoClick(e)}/>
                        :
                        <div className={st.loadBG}>
                            <Logo width={"15%"} height={"15%"}/>
                        </div>
                    }
                    
                    {/*<div className={st.insetShadow}></div>*/}

                    <ImageLogo className={st.imageLogo}/>
                    <ArrowLeft className={st.arrowLeft}/>
                    <div className={st.arrowLeftBlock} id={"arrowLeft"} onClick={changeCurrentImgByArrow}></div>
                    <ArrowRight className={st.arrowRight}/>
                    <div className={st.arrowRightBlock} id={"arrowRight"} onClick={changeCurrentImgByArrow}></div>
                </div>
                {isOpenedPhoto &&
                    <div className={cn(st.currentPhotoBlock)}>
                        <div className={st.loadBG}>
                            <Logo width={"15%"} height={"15%"}/>
                        </div>
                    </div>
                }
                <div className={st.sliderBottomBlock} id="sliderBottomBlock">
                    <div className={st.sliderBottom} id="sliderBottom" style={{left: `${position}px`}}>
                        {project.photos.map((p, id) =>
                            <div className={cn(st.miniPhotoBlock, {[st.miniPhotoBlockActive]: currentImg === id})}
                                 id={id}  key={id}>
                                {photoUrl[id] ?
                                        <img className={st.miniPhoto} onLoad={(e) => resizeImg(e, id)} onClick={updateCurrentImg} id={id} src={photoUrl[id]} alt={"MiniPhoto"}/>
                                     :
                                    <div className={st.loadBG}>
                                        <Logo width={"50%"} height={"50%"}/>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
                <div className={cn({[st.closeButton]: isOpenedPhoto})} style={{display: 'none'}} onClick={() => setIsOpenedPhoto(false)} >
                    <Close style={{width: '100%', height: '100%'}}  />
                </div>
            </div>
        </>


    );
};

export default Slider;