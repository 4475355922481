import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../axios";
export const fetchProjects = createAsyncThunk("projects", async () => {
    const { data } = await axios.get('/projects');
    return data
})

const initialState = {
    projects: {
        items: [],
        status: 'loading'
    }
};

const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    // отлавливатели состояния запроса на сервер
    extraReducers: {
        [fetchProjects.pending]: (state) => {
            state.projects.items = [];
            state.projects.status = 'loading'
        },
        [fetchProjects.fulfilled]: (state, action) => {
            state.projects.items = action.payload;
            state.projects.status = 'loaded';
        },
        [fetchProjects.rejected]: (state) => {
            state.projects.items = [];
            state.projects.status = 'error';
        }, // действия относительно состояний загрузки данных
    }
})

export const projectsReducer = projectSlice.reducer;