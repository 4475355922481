import React from 'react';

const Logo = ({width = "65", height = "65"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.0109 30.1299C37.7602 34.304 34.3269 37.7462 30.1576 40.0065C34.3269 42.2667 37.7602 45.709 40.0109 49.8831C42.2616 45.709 45.6954 42.2667 49.8646 40.0065C45.6954 37.7462 42.2616 34.304 40.0109 30.1299Z" fill="#52B26E"/>
            <path d="M26.7422 22.8764C26.6599 23.0517 26.5673 23.2163 26.4538 23.3814C25.6606 24.6894 24.5371 25.7811 23.2075 26.5535C21.8477 27.3467 20.2706 27.8103 18.5702 27.8103H18.5498C16.8596 27.8103 15.283 27.3569 13.9223 26.5637C12.5624 25.7914 11.4287 24.6791 10.6253 23.3502C10.5425 23.2163 10.4703 23.0926 10.3982 22.9587C9.67715 21.6405 9.27474 20.1368 9.27474 18.5401C9.27474 15.1104 11.1403 12.1131 13.9223 10.5164C15.283 9.72363 16.8596 9.26982 18.5498 9.26982H18.5702C20.2706 9.26982 21.8477 9.73386 23.2075 10.5266C25.9695 12.1336 27.8243 15.1206 27.8243 18.5401C27.8243 20.1056 27.4331 21.5787 26.7422 22.8764ZM34.6156 9.26982L34.5746 9.19821C33.2246 6.891 31.4004 4.91299 29.226 3.38879C28.7831 3.05912 28.3193 2.77084 27.8448 2.50301C25.1247 0.916973 21.93 0.010273 18.5498 4.19617e-05C15.1695 4.19617e-05 11.9949 0.916977 9.27474 2.49278C6.57509 4.0379 4.30762 6.26235 2.67937 8.93038C2.61796 9.04337 2.54538 9.15683 2.48397 9.26982C2.44257 9.34189 2.40163 9.41442 2.36023 9.4865C2.31882 9.55857 2.27742 9.63064 2.23648 9.70271C0.814312 12.3294 0.000183105 15.3368 0.000183105 18.5401V37.0801H9.27474V34.5873C11.9949 36.1631 15.1695 37.0801 18.5498 37.0801C21.9091 37.0698 25.1247 36.1631 27.8448 34.5771C28.3193 34.3093 28.7831 34.021 29.226 33.6913C31.4214 32.1569 33.266 30.1482 34.6156 27.8103C34.6258 27.8001 34.6258 27.7894 34.6365 27.7791C36.2029 25.06 37.0994 21.9084 37.0994 18.5401C37.0994 15.1615 36.2029 11.9997 34.6156 9.26982Z" fill="#2358A4"/>
            <path d="M77.7641 9.70283C77.6818 9.55869 77.5887 9.40432 77.5064 9.26994C76.6718 7.80759 75.6311 6.47869 74.435 5.30462C73.3325 4.21286 72.0754 3.26523 70.7259 2.49244C70.7259 2.49244 70.7259 2.48221 70.7152 2.48221C69.3753 1.70988 67.9327 1.09193 66.4077 0.679962L64.1198 4.61459L61.4299 9.23925L61.4094 9.26994L58.7302 13.8741L56.0194 18.5402L53.3816 23.0928H53.3714C53.3505 23.0514 53.3198 22.9998 53.2993 22.9584C53.2788 22.9282 53.2681 22.9072 53.2579 22.8761C52.5675 21.5788 52.1763 20.1058 52.1763 18.5402C52.1763 17.1392 52.4954 15.8001 53.0523 14.6055C53.2788 14.1317 53.5366 13.6886 53.825 13.2664L56.1022 9.33178L56.1334 9.26994L61.4299 0.102924V0.000164032C58.0501 0.000164032 54.8862 0.91663 52.1553 2.50313C51.6813 2.77049 51.2175 3.05925 50.7746 3.38891C48.6001 4.91311 46.7658 6.90088 45.426 9.2081L45.3953 9.26994C43.808 11.9994 42.9012 15.1617 42.9012 18.5402C42.9012 21.9182 43.808 25.0805 45.3953 27.81L45.4153 27.8406C46.7453 30.1586 48.5899 32.1668 50.7746 33.6914C51.2175 34.0207 51.6813 34.3094 52.1553 34.5772C53.5054 35.3598 54.948 35.9777 56.4832 36.4004L58.7512 32.476L61.4299 27.8304L64.1198 23.1853L66.799 18.5402L69.4786 13.9048C70.2718 15.2649 70.7259 16.8509 70.7259 18.5402C70.7259 20.1364 70.3234 21.6406 69.6024 22.9584C69.5298 23.0928 69.4577 23.2165 69.3753 23.3499L66.799 27.81L61.4299 37.0802H61.4508C64.8311 37.0802 67.9946 36.1735 70.7152 34.5977C73.5283 32.9805 75.9088 30.6012 77.5269 27.7895C77.6097 27.6556 77.6818 27.5217 77.7641 27.3775C79.1858 24.7509 79.9999 21.7434 79.9999 18.5402C79.9999 15.3365 79.1858 12.329 77.7641 9.70283Z" fill="#52B26E"/>
            <path d="M34.533 70.7302L29.257 61.5116L29.2258 61.5734V61.4604L26.742 57.1236C26.7016 57.0311 26.6495 56.9385 26.5876 56.8562L29.2258 52.3036L29.2877 52.1902L31.5654 48.2556C30.8439 47.5344 30.0604 46.8857 29.2258 46.2985C28.783 45.9795 28.3298 45.6907 27.8553 45.4229C26.5057 44.6301 25.0421 44.0122 23.4962 43.5997L21.239 47.5037L18.5701 52.1181L18.5291 52.1902L15.8699 56.7944L13.1703 61.4604L10.5116 66.0642C9.71838 64.7148 9.27502 63.139 9.27502 61.4604V61.2163C9.27502 59.7498 9.66255 58.3098 10.3985 57.0413C10.4702 56.9078 10.5423 56.7837 10.6251 56.6605L13.2015 52.1902H13.2112L18.5701 42.9302V42.92H18.5496C15.1693 42.92 12.0058 43.8164 9.27502 45.4025C6.57491 46.9578 4.30791 49.1827 2.67965 51.8503C2.61777 51.9637 2.54567 52.0767 2.48379 52.1902C2.48379 52.18 2.47309 52.1902 2.48379 52.1902C2.40191 52.3241 2.3084 52.4785 2.23676 52.6226C0.814128 55.2493 0 58.2567 0 61.4604C0 64.6632 0.814128 67.6711 2.23676 70.2973C2.2777 70.3699 2.31864 70.4415 2.36004 70.514C2.40145 70.5861 2.44239 70.6581 2.48379 70.72V70.7302C2.54567 70.8432 2.61777 70.9567 2.67965 71.0701C3.48308 72.3883 4.44189 73.5935 5.53422 74.6644C6.64701 75.7771 7.90403 76.7349 9.27502 77.5179C10.6144 78.3005 12.0575 78.9082 13.5825 79.3202L15.8699 75.3651L18.5496 70.7302H18.5603L18.5701 70.7098L21.2497 66.0846L23.2078 69.4734L23.9293 70.7302H23.9396L27.8553 77.497C28.3298 77.2297 28.783 76.9409 29.2258 76.6215C31.4003 75.087 33.2244 73.1095 34.5744 70.8023L34.533 70.7302Z" fill="#52B26E"/>
            <path d="M77.7638 52.6226C77.6819 52.4785 77.5888 52.3241 77.5065 52.1902C77.5065 52.2004 77.5065 52.2004 77.5065 52.1902C75.8885 49.3785 73.5387 47.0299 70.7255 45.4025L66.7991 52.1902L66.0775 53.4261C67.428 54.2091 68.572 55.3214 69.3754 56.6605C69.4578 56.7837 69.5299 56.9074 69.602 57.0413C70.3235 58.36 70.7255 59.853 70.7255 61.4604C70.7255 64.9003 68.8604 67.8976 66.0775 69.4939C64.7172 70.2769 63.1094 70.7302 61.43 70.7302C59.7399 70.7302 58.153 70.2666 56.7927 69.4734L56.0712 70.7302L52.1452 77.497C54.876 79.0835 58.0707 79.9898 61.4509 80C64.8312 80 67.9942 79.104 70.7255 77.518C73.5387 75.8905 75.8987 73.5317 77.5167 70.72C77.5991 70.5856 77.6819 70.4415 77.7638 70.2973C79.1859 67.6711 80 64.6632 80 61.4604C80 58.2568 79.1859 55.2493 77.7638 52.6226Z" fill="#2358A4"/>
            <path d="M61.4299 61.4603H50.7127L45.3743 52.2212C45.3743 52.2105 45.3743 52.2003 45.3948 52.1901C46.7346 49.8522 48.5792 47.8435 50.7746 46.2988C51.2175 45.9794 51.6706 45.6906 52.1451 45.4233L56.0711 52.2003L56.7926 53.4469L61.4299 61.4603Z" fill="#2358A4"/>
        </svg>
    );
};

export default Logo;