import React from 'react';
import st from "./Preview.module.css";
// import vid from "../../image/preview/videoplayback2.mp4";
import vidNew from "../../image/preview/previewNew.mp4";
// import vid2 from "../../image/preview/mkv.mkv";
import photo from "../../image/preview/videoplayback2_Moment.jpg";
import {ReactComponent as Logo} from "../../image/icons/blackLogo.svg";
import MainSlider from "../UI/MainSlider/MainSlider";

const Preview = () => {

    return (
        <div className={st.previewBlock}>
            <div className={st.previewVideoBlock}>
                <div className={st.trapezoid}></div>
                {/*<video className={st.previewVideo} poster={photo} autoPlay muted playsInline loop>*/}
                {/*    <source src={vidNew} type="video/mp4" />*/}
                {/*</video>*/}
                <div className={st.previewVideo}>
                    <MainSlider/>
                </div>
            </div>
            <div className={st.imageTextContainer}>
                <span className={st.titleText}>СТРОИТЕЛЬНАЯ КОМПАНИЯ</span>
                <div className={st.leftBlock}>
                    <span className={st.titleTextLeft}>Специализируемся на ремонте квартир, домов и офисов в Москве</span>
                    <div className={st.line}></div>
                    <span className={st.leftBlockText}>Закажите бесплатную консультацию уже сейчас</span>
                    <a href={"#feedback"}><button className={st.contactButton}>Связаться с нами</button></a>
                </div>
                {/*<Logo className={st.logoBlock}/>*/}
            </div>

        </div>
    );
};
export default Preview;