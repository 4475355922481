import st from "./Connect.module.css"
import "./Connect.module.css"
import {set, useForm} from "react-hook-form";
import {ReactComponent as Logo} from "../../image/logo.svg";
import {ReactComponent as ArrowRight} from "../../image/icons/right-arrow-angle.svg";
import {ReactComponent as ArrowLeft} from "../../image/icons/left-arrow-angle.svg";
import {ReactComponent as Message} from "../../image/icons/message-icon.svg";
import {ReactComponent as Phone} from "../../image/icons/phone-call.svg";
import {ReactComponent as CallBack} from "../../image/icons/connect/callbacks.svg";
import {ReactComponent as ActiveProjects} from "../../image/icons/connect/activProjects.svg";
import {ReactComponent as CompletedProjects} from "../../image/icons/connect/complitedProjects.svg";
import axios from "../../axios";
import 'react-phone-number-input/style.css'
import React, {useEffect, useState} from "react";
import logo from "../../image/logo";
import cn from "classnames";
import {useInView} from "react-intersection-observer";
import {NotificationManager} from "react-notifications";
import {Link} from "react-router-dom";
import InputMask from 'react-input-mask';

const Connect = () => {
    const [amountOfCallback, setAmountOfCallback] = useState("Загрузка...");
    const [toggle, setToggle] = useState("message");

    const {ref: stepTitleRef1, inView: isView10} = useInView();
    const {ref: stepTitleRef2, inView: isView20} = useInView();
    const {ref: stepTitleRef3, inView: isView30} = useInView();

    const [hoveredStatTitle1, setHoveredStatTitle1] = useState(false);
    const [hoveredStatTitle2, setHoveredStatTitle2] = useState(false);
    const [hoveredStatTitle3, setHoveredStatTitle3] = useState(false);

    useEffect(() => {
        if (isView10)
            setHoveredStatTitle1(true)
        if (isView20)
            setHoveredStatTitle2(true)
        if (isView30)
            setHoveredStatTitle3(true)
        
    }, [isView10, isView20, isView30])

    useEffect(() => {
        axios.get(`/callbacks/amount`).then(res => {
            setAmountOfCallback(res.data)
        });
    }, [amountOfCallback])


    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        defaultValues: {
            name: "",
            phoneNumber: "",
            email: "",
            topic: "",
            policy: false
        },
        mode: "onTouched"
    })

    const onSubmit = async (data) => {

        const ans = await axios.post('/callbacks', data);
        if (ans.status === 200) {
            console.log(123123123)
            createNotification('success')
            reset()
            setAmountOfCallback(amountOfCallback + 1)
        } else {
            alert(ans.data?.err)
        }

    }
    
    const changeToggle = () => {
        console.log(toggle)
        if (toggle === "message") setToggle("phone")
        else setToggle("message")
    }

    const createNotification = (type) => {
        switch (type) {
            case 'info':
                NotificationManager.info('Info message');
                break;
            case 'success':
                NotificationManager.success('Ваша заявка успешно отправлена', '');
                break;
            case 'warning':
                NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                break;
            case 'error':
                NotificationManager.error('Error message', 'Click me!', 5000, () => {
                    alert('callback');
                });
                break;
        }
    };

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }

    return (
        <div className={st.fullContainer}>
            <div id={"feedback"} className={st.container}>

                <div className={`noticesBlock ${st.noticesBlock}`}>


                </div>

                <div className={st.contactUs}>
                    <div className={st.bg}>
                        <img className={st.img} src={require("../../image/preview/newPreview.jpg")} alt={"contactUsBG"}/>
                        <div className={st.blocks}>
                            
                            <div className={st.connectBlock}>
                                <div className={st.content}>
                                    <div className={st.title}>
                                        <span>ОБРАТНАЯ СВЯЗЬ</span>
                                        <Logo className={st.logo}/>
                                    </div>
                                    <div className={st.underTitleBlock}>
                                    <span
                                        className={st.connectText}>Оставьте заявку и мы свяжемся с вами в ближайшее время!</span>
                                        <div className={st.changeConnectBlock} onClick={changeToggle}>
                                            <div className={cn(st.toggleIconBlock, {
                                                [st.toggleIconBlockMessage]: toggle === "message",
                                                [st.toggleIconBlockPhone]: toggle === "phone"
                                            })}>
                                                {toggle === "message"
                                                    ?
                                                    <Message className={st.toggleIcon}/>
                                                    :
                                                    <Phone className={st.toggleIcon}/>
                                                }
                                            </div>
                                            <div className={cn(st.toggle, {
                                                [st.toggleMessage]: toggle === "message",
                                                [st.togglePhone]: toggle === "phone"
                                            })}>
    
                                            </div>
                                            <div className={cn(st.toggleTextBlock, {
                                                [st.toggleTextBlockMessage]: toggle === "message",
                                                [st.toggleTextBlockPhone]: toggle === "phone"})}>
                                                <div className={cn(st.toggleText, st.toggleTextMessage)}>Письмо</div>
                                                <div className={cn(st.toggleText, st.toggleTextPhone)}>Звонок</div>
                                                {/*<span className={st.toggleText}>{toggle}</span>*/}
                                            </div>
                                            <div className={cn(st.arrowsBlock, {[st.reverse]: toggle === "message"})}>
                                                <ArrowLeft className={cn(st.arrow, st.arrow1Left)}/>
                                                <ArrowLeft className={cn(st.arrow, st.arrow2Left)}/>
                                                <ArrowRight className={cn(st.arrow, st.arrow1Right)}/>
                                                <ArrowRight className={cn(st.arrow, st.arrow2Right)}/>
                                            </div>
                                        </div>
                                    </div>
    
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <input
                                            className={st.input}
                                            type={"text"}
                                            placeholder={"ИМЯ"}
                                            {...register("name", {
                                                required: "Укажите имя",
                                                minLength: {
                                                    value: 2,
                                                    message: "Минимум 2 символа"
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: "Максимум 50 символов"
                                                }
                                            })}
                                        />
                                        <div className={st.inputErrorMessage}>
                                            {errors?.name && <p>{errors?.name?.message}</p>}
                                        </div>
    
                                        <input
                                            id={"email"}
                                            className={cn(st.input, {[st.hidden]: toggle === "phone"})}
                                            type={"text"}
                                            placeholder={"EMAIL"}
                                            {...register("email", {
                                                required: "Укажите почту",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Некорректный email"
                                                },
                                                disabled: toggle === "phone"
                                            })}
                                        />
                                        <div className={cn(st.inputErrorMessage, {[st.hidden]: toggle === "phone"})}>
                                            {errors?.email && <p>{errors?.email?.message}</p>}
                                        </div>
    
                                        <InputMask
                                            id={"phone"}
                                            className={cn(st.input, {[st.hidden]: toggle === "message"})}
                                            type={"text"}
                                            mask="+7 (999) 999-99-99"
                                            placeholder={"НОМЕР ТЕЛЕФОНА"}
                                            {...register("phoneNumber", {
                                                required: "Укажите номер",
                                                pattern: {
                                                    value: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/,
                                                    message: "Некорректный номер"
                                                },
                                                disabled: toggle === "message"
                                            })}
                                        />
                                        <div className={cn(st.inputErrorMessage, {[st.hidden]: toggle === "message"})}>
                                            {errors?.phoneNumber && <p>{errors?.phoneNumber?.message}</p>}
                                        </div>
    
                                        <input
                                            className={st.input}
                                            type={"text"}
                                            style={{width: "calc(100% / 2)"}}
                                            placeholder={"ТЕМА"}
                                            {...register("topic")}
                                        />
    
                                        <div className={st.checkbox}>
                                            <input
                                                type="checkbox"
                                                id="agreement"
                                                {...register("policy", {required: "Обязательное поле"})}
                                            />
                                            <label htmlFor="agreement"><span>Я согласен на <Link to={`/about-company#policy`} className={st.policyLink} >обработку</Link> персональных данных</span></label>
                                            <div className={st.inputErrorMessage}>
                                                {errors?.policy && <p>{errors?.policy?.message}</p>}
                                            </div>
                                        </div>
    
                                        <div className={st.subButtonBox}>
                                            <button type={"submit"} className={st.submitButton}>Отправить</button>
                                        </div>
    
                                    </form>
                                </div>
                            </div>
                            <div className={st.statBlocks}>
                                <div className={st.statBlock}>
                                    <CallBack className={st.statIcon}/>
                                    <div>
                                        <p className={cn(st.statBlockTitle, {[st.statBlockTitleHovered]: hoveredStatTitle1})}>{amountOfCallback}</p>
                                        <p ref = {stepTitleRef1} className={cn(st.statBlockText, {[st.statBlockTextHovered]: hoveredStatTitle1})}>Заявок от клиентов</p>
                                    </div>
                                </div>
                                <div className={st.statBlock}>
                                    <ActiveProjects className={st.statIcon}/>
                                    <div>
                                        <p className={cn(st.statBlockTitle, {[st.statBlockTitleHovered]: hoveredStatTitle2})}>22</p>
                                        <p ref = {stepTitleRef2} className={cn(st.statBlockText, {[st.statBlockTextHovered]: hoveredStatTitle2})}>Активных проекта</p>
                                    </div>
                                </div>
                                <div className={st.statBlock}>
                                    <CompletedProjects className={st.statIcon}/>
                                    <div>
                                        <p className={cn(st.statBlockTitle, {[st.statBlockTitleHovered]: hoveredStatTitle3})}>63</p>
                                        <p ref = {stepTitleRef3} className={cn(st.statBlockText, {[st.statBlockTextHovered]: hoveredStatTitle3})}>Завершенных проектов</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default Connect;