import {configureStore} from "@reduxjs/toolkit";
import {projectsReducer} from "./slices/projects";
import {coordinatesReducer} from "./slices/coordinates";
// import {authReducer} from "./slices/auth";

// подключение в харнилище редьюсеров
const store = configureStore({
    reducer: {
        projects: projectsReducer,
        coordinates: coordinatesReducer,
        // auth: authReducer
    }
})

export default store;