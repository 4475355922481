import React, {useEffect, useState} from 'react';
import st from "./OurProjects.module.css"
import Filter from "../../components/Filter/Filter";
import { MiniProject } from "../../components/UI/MiniProject/MiniProject";
import { filterProjects } from "../../hooks/useFilterProjects";
import {useDispatch, useSelector} from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import {fetchProjects} from "../../redux/slices/projects";
import ProjectsMap from "../../components/ProjectsMap/ProjectsMap";
import ProjectSkeleton from "../../components/UI/ProjectSkeleton/ProjectSkeleton";

export const OurProjects = () => {
    const dispatch = useDispatch();
    const { projects } = useSelector(state => state.projects)

    const isProjectsLoading = projects.status === "loading";


    // данные о всех проектах
    const [sortedProjectsInfo, setSortedProjectsInfo] = useState([])
    // дефолтные значения для фильтров на этой странице (передаем как пропсы)
    const [filters, setFilters] = useState({
        flats: false,
        houses: false,
        offices: false,
        oneRoom: false,
        twoRoom: false,
        threeRoom: false,
        fourPlusRoom: false,
        sMin: 1,
        sMax: 550,
        sortBy: "date",
        sortToUp: false,
        openedMap: false,
        onlyCompleted: true
    });


    useEffect(() => {
        // ourRequest.cancel()
        if (projects.items.length === 0)
            dispatch(fetchProjects())
        // axios.get("/projects").then(res => {
        //     setProjectsInfo(res.data)
        //     // console.log(res.data[0]);
        // });
    }, [dispatch])
    // const sortedProjectsInfo = [];

    // console.log(projects)
    // console.log(isProjectsLoading)

    useEffect(() => {
        // здесь идет фильтрация и сортировка
        // console.log(projects.items)
        if (projects.items.length !== 0)
            setSortedProjectsInfo(filterProjects(projects.items, filters));
    }, [projects.items, filters])



    // отключено, тк идет отслеживание состояния шапки и каждый раз новый рандом перерисовывает проекты
    // это используется, чтобы в map всегда отрисовывались посты с новыми ключами и всегда была анимация
    // const random = getRandomInt(9999999);

    return (
        <div className={st.fullContainer}>
            <span className={st.ourProjectsText}>НАШИ ПРОЕКТЫ</span>
            <Filter filters={filters} setFilters={setFilters} projectsInfo={projects.items}/>
            <ProjectsMap isOpened={filters.openedMap} filters={filters} projects={projects}/>

            <div className={st.gallery}>

                {(isProjectsLoading ? [...Array(6)] : sortedProjectsInfo).map((project, id) =>
                    isProjectsLoading ? (
                        <ProjectSkeleton />
                        ) : (
                            <MiniProject isLoading={false} project={project} key={project.id} width={0}/>
                        )
                )}

                {(sortedProjectsInfo.length === 0
                        ?
                        (
                            <div className={st.notFound}>По вашему запросу ничего не
                                найдено</div>
                        ) : (
                            <></>
                        )
                )}

                {/* если кол-во видимых постов не равно кол-ву пришедших данных */}
                {/*{numOfVisPro < sortedProjectsInfo.length &&*/}
                {/*    <button className={st.showMore} onClick={() => setNumOfVisProj(numOfVisPro + 6)}>ПОКАЗАТЬ ЕЩЕ</button>*/}
                {/*}*/}
            </div>


        </div>

    );
};
