import React, {useState} from 'react';
import st from "./Filter.module.css";
import {ReactComponent as Arrow} from "../../image/chevron.svg";
import cn from "classnames";
import {ReactComponent as U} from "../../image/U.svg";
import {Slider, Typography} from "@mui/material";
import SortButton from "../UI/SortButton/SortButton";
import MyCheckbox from "../UI/MyCheckbox/MyCheckbox";
import {Link} from "react-router-dom";
import MyFilterButton from "../UI/MyFilterButton/MyFilterButton";
import logo from "../../image/logo";

const Filter = ({ filters, setFilters, projectsInfo }) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [searchedProjects, setSearchedProjects] = useState([]);

    const searchProjects = (e) => { // функция для поля поиска проекта
        let arr = [];
        if (e.target.value !== "") {
            projectsInfo.forEach((p) => {
                if (p.name.toLowerCase().includes(e.target.value.toLowerCase()) && p.completed) {
                    arr.push(p);
                }
            })
            setSearchedProjects(arr);
        } else {
            setSearchedProjects([]);
        }
    }
    
    const updateFilter = (type, key) => {
        const newFilters = {}; // создаем новый словарь, чтобы сработал useEffect на главное странице
        if (type === "filter") {
            console.log(type)
            console.log(key)
            filters[key] = !filters[key]
        } else if (type === "slider") {
            console.log(key)
            filters["sMax"] = key.target.value[1];  // обнволяем значение у полученных фильтров
            filters["sMin"] = key.target.value[0];  // обнволяем значение у полученных фильтров
        } else if (type === "sortButton") {
            if (filters["sortBy"] === key) {
                filters.sortToUp = !filters.sortToUp;
            } else {
                filters["sortBy"] = key;
            }
        }
        
        for (const key in filters) { // копируем
            newFilters[key] = filters[key];
        }
        setFilters(newFilters); // сетим
    }

    const updateSlider = (e) => {
        const newFilters = {}; // создаем новый словарь, чтобы сработал useEffect на главное странице
        if (e.target.name === "slider") {
            filters["sMax"] = e.target.value[1];  // обнволяем значение у полученных фильтров
            filters["sMin"] = e.target.value[0];  // обнволяем значение у полученных фильтров
        }

        for (const key in filters) { // копируем
            newFilters[key] = filters[key];
        }
        setFilters(newFilters); // сетим
    }
    
    const openMap = () => {
        const newFilters = {}; // создаем новый словарь, чтобы сработал useEffect на главное странице
        filters.openedMap = !filters.openedMap;

        for (const key in filters) { // копируем
            newFilters[key] = filters[key];
        }
        setFilters(newFilters); // сетим
    }




    return (
        <div>
            <div className={st.container}>
                <div className={st.showFilters}>
                    <button id="showButton" onClick={() => setIsFilterOpen(!isFilterOpen)}>Показать фильтры</button>
                    <label htmlFor="showButton">
                        <Arrow className={cn(st.arrow, {[st.arrowOpened]: isFilterOpen})} width="25"/>
                    </label>
                </div>
            </div>
            <section>
                <hr/>  {/* сам блок фильтров */}
                <div className={cn(st.filtersClosed, {[st.filtersOpened]: isFilterOpen})}> {/* если фильтры не открыты, то навешиваем класс закрытых фильтров */}
                    <div className={st.container}>
                        <div className={st.filtersAndIm}>
                            <div className={st.types}>
                                <div className={cn(st.typesOfWorkPlace, {[st.filterButtonsClosed]: !isFilterOpen})}>
                                    <MyFilterButton filters={filters} onChange={updateFilter} type={"filter"} keyValue={"flats"} text={"Квартиры"}/>
                                    <MyFilterButton filters={filters} onChange={updateFilter} type={"filter"} keyValue={"offices"} text={"Офисы"}/>
                                    <MyFilterButton filters={filters} onChange={updateFilter} type={"filter"} keyValue={"houses"} text={"Дома"}/>
                                </div>
                                <div className={cn(st.numberOfRooms, {[st.filterButtonsClosed]: !isFilterOpen})}>
                                    <span>Комнат:</span>
                                    <MyFilterButton filters={filters} onChange={updateFilter} type={"filter"} keyValue={"oneRoom"} text={"1"}/>
                                    <MyFilterButton filters={filters} onChange={updateFilter} type={"filter"} keyValue={"twoRoom"} text={"2"}/>
                                    <MyFilterButton filters={filters} onChange={updateFilter} type={"filter"} keyValue={"threeRoom"} text={"3"}/>
                                    <MyFilterButton filters={filters} onChange={updateFilter} type={"filter"} keyValue={"fourPlusRoom"} text={"4+"}/>
                                </div>
                            </div>
                            <U className={st.logoU}/>
                        </div>
                        <div className={st.slider}>
                            <Typography id="slider" gutterBottom className={st.typography} style={{fontWeight: 400}}>
                                {filters["sMin"] === 1 && filters["sMax"] === 550 ?
                                    "Площадь: любая"
                                    : "Площадь: от " + filters["sMin"] + ' м² до ' + filters["sMax"] + " м²"

                                }

                            </Typography>
                            <div className={st.sliderBox}>

                                <Slider
                                    // getAriaLabel={() => 'Temperature range'}  // тип слайдера
                                    id="slider2"
                                    aria-labelledby="slider"
                                    value={[filters["sMin"], filters["sMax"]]}
                                    name="slider"
                                    onChange={updateSlider}
                                    // valueLabelDisplay="auto"  // подсвечивание значения свапа
                                    min={1}
                                    max={550}


                                    disableSwap // отключил свап тоглов
                                    // getAriaValueText={valuetext}

                                    sx ={{
                                        margin: "15px 0px 0px 0px",
                                        padding: 0,
                                        color: '#29597E',
                                        height: 8,
                                        '& .MuiSlider-thumb': {
                                            height: 24,
                                            width: 24,
                                            backgroundColor: '#FFF',
                                            border: '3px solid #29597E',
                                            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                                boxShadow: 'inherit',
                                            },
                                            '&:before': {
                                                display: 'none',
                                            },
                                        },

                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className={st.container}>
                    <div className={st.sort}>
                        <div className={st.sortBlock}>
                            <span className={st.sortByText}>Сортировать по:</span>
                            <SortButton filters={filters} onChange={updateFilter} type={"sortButton"} keyValue={"date"} text={"Дате"}/>
                            <SortButton filters={filters} onChange={updateFilter} type={"sortButton"} keyValue={"area"} text={"Площади"}/>
                            <SortButton filters={filters} onChange={updateFilter} type={"sortButton"} keyValue={"popular"} text={"Популярности"}/>
                        </div>
                        <div className={st.mapAndSearchBlock}>
                            <div onClick={openMap} className={cn(st.mapButton, {[st.mapButtonHovered]: filters.openedMap === true})}>
                                <span>Показать проекты на карте</span>
                            </div>
                            <input type={"text"} className={st.search} onChange={searchProjects} placeholder={"Поиск по проектам..."}/>
                            <div className={st.searchList}>
                                {searchedProjects.map((project) =>
                                    <Link to={`/projects/${project.id}`} key={project.id}>
                                        <div className={st.searchItem} key={project.id}>
                                            <span>{project.name}</span>
                                            <span>{project.date}</span>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default Filter;