import React from 'react';
import st from "./OurBlock.module.css";
import cn from "classnames";
import {ReactComponent as Checks} from "../../../image/icons/checks.svg";
import {useTheme} from '../../../hooks/useTheme'

const OurBlock = ({children, position, hovered, text}) => {
    const {theme, setTheme} = useTheme();
    return (
        <div className={cn(
            {[st.firstBlock]: position === "firstBlock"},
            {[st.secondBlock]: position === "secondBlock"},
            {[st.thirdBlock]: position === "thirdBlock"},
            {[st.forthBlock]: position === "forthBlock"},
            )}>
            <div className={cn(st.block, {[st.blockHovered]: hovered})}>
                {children}
                <div className={st.blockBg}>
                    <div className={st.title}>
                        <span>{text.title}</span>
                    </div>
                    <div className={st.benefits}>
                        <div className={st.benefit}>
                            <Checks className={st.icon}/>
                            <span>{text.benefit1}</span>
                        </div>
                        <div className={st.benefit}>
                            <Checks className={st.icon}/>
                            <span>{text.benefit2}</span>
                        </div>
                        <div className={st.benefit}>
                            <Checks className={st.icon}/>
                            <span>{text.benefit3}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurBlock;