import React from 'react';
import cn from "classnames";
import st from "./SortButton.module.css";
import {ReactComponent as Arrow} from "../../../image/chevron.svg";

const SortButton = ({ filters, onChange, type, keyValue, text}) => {
    return (

        <div onClick={() => onChange(type, keyValue)} className={cn(st.sortButton, {[st.sortButtonSelected]: filters.sortBy === keyValue})}>
            <span>{text}</span>
            <Arrow className={cn(st.arrow, {[st.arrowOpened]: (filters.sortBy === keyValue && filters.sortToUp)})}/>
        </div>
    );
};

export default SortButton;