import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import React, {useEffect} from "react";
import {Main, OurProjects, Project} from "./pages/export";
import {ReactComponent as Anchor} from "./image/icons/anchorArrow2.svg";
import Footer from "./components/Footer/Footer";
import Connect from "./components/Connect/Connect";
import OurBlogs from "./pages/OurBlogs";
import Blog from "./pages/Blog";
import {ReactComponent as Logo} from "./image/logo.svg";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import AboutCompany from "./pages/AboutCompany";
import Contacts from "./pages/Contacts";

function App() {

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }
    const anchorShow = () => {
        const scroll = document.querySelector('.anchor');
        scroll.classList.toggle("active", window.scrollY > 500);
    }


    useEffect(() => {
        let element = document.querySelector(".previewWhiteSpace");

        setTimeout(() => {
            element.style.display = "none";
        }, 2100);

        window.addEventListener('scroll', anchorShow);
        return () => {
            window.removeEventListener('scroll', anchorShow);
        };
    });

    // const ourRequest = Axios.CancelToken.source()
    //
    // const [request, setRequest] = useState(ourRequest);
    // const value = useMemo(
    //     () => ({ request, setRequest }),
    //     [request]
    // );



    return (
    <div className="App">

            <div className="previewWhiteSpace" style={{display: 'block'}}>
                <Logo/>
            </div>
            <Navbar/>
            {/*<video className="previewVideo" autoPlay muted playsInline loop >*/}
            {/*    <source src={vid} type="video/mp4" style={{objectFit: "contain"}}/>*/}
            {/*</video>*/}
            <Switch>
                <Route path="/home"><Main/></Route>
                <Route path="/projects/:id"><Project/></Route>
                <Route path="/projects"><OurProjects/></Route>
                <Route path="/blog/:id"><Blog/></Route>
                <Route path="/blog"><OurBlogs/></Route>
                <Route path="/about-company"><AboutCompany/></Route>
                <Route path="/contacts"><Contacts/></Route>
                <Redirect to="/home"/>
            </Switch>
            <Connect/>
            <Footer/>
            <Anchor className="anchor" onClick={scrollTop}/>
            <NotificationContainer/>

    </div>
  );
}

export default App;
