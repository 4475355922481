import React, {useEffect, useRef, useState} from 'react';
import st from "./AboutUs.module.css";
import st3 from "../UI/OurBlock/Figures.module.css";
import MainLogoIcon from "../UI/MainLogoIcon/MainLogoIcon";
import cn from "classnames";
import {ReactComponent as Y} from "../../image/icons/path66.svg";
import {ReactComponent as YDark} from "../../image/icons/path66Dark.svg";
import {ReactComponent as Star} from "../../image/icons/path50.svg";
import {ReactComponent as P} from "../../image/icons/path54.svg";
import {ReactComponent as PDark} from "../../image/icons/path54Dark.svg";
import {ReactComponent as K} from "../../image/icons/path62.svg";
import {ReactComponent as KDark} from "../../image/icons/path62Dark.svg";
import {ReactComponent as U} from "../../image/icons/path58.svg";
import {ReactComponent as UDark} from "../../image/icons/path58Dark.svg";
import {ReactComponent as L} from "../../image/icons/path70.svg";
import OurBlock from "../UI/OurBlock/OurBlock";
import {useInView} from "react-intersection-observer";
import {useTheme} from '../../hooks/useTheme'
const AboutUs = () => {
    const {theme, setTheme} = useTheme();
    const [hovered, setHovered] = useState(false);
    const [hovered1, setHovered1] = useState(false);
    const [hovered2, setHovered2] = useState(false);
    const [hovered3, setHovered3] = useState(false);
    const [hovered4, setHovered4] = useState(false);

    const {ref: myRef, inView: isView} = useInView();
    const {ref: myRef1, inView: isView1} = useInView();
    const {ref: myRef2, inView: isView2} = useInView();
    const {ref: myRef3, inView: isView3} = useInView();
    const {ref: myRef4, inView: isView4} = useInView();

    const text = [
        {
            title: "Профессиональный ремонт",
            benefit1: "Более 10 лет на рынке",
            benefit2: "Профессиональный опыт в каждом пространстве: квартиры, офисы, дома",
            benefit3: "Проекты по всей Москве, Московской области и за ее пределами"
        },
        {
            title: "Инновационные подходы к работе",
            benefit1: "Новейшее оборудование",
            benefit2: "Внедрение современных технологий",
            benefit3: "Надежность и качество благодаря передовым технологиям"
        },
        {
            title: "Удовольствие от результата",
            benefit1: "Безупречное выполнение каждой работы",
            benefit2: "Современные решения для вашего помещения",
            benefit3: "Качественная работа на долгие годы"
        },
        {
            title: "Комфортное сотрудничество",
            benefit1: "Индивидуальный подход к каждому клиенту",
            benefit2: "Согласование каждого этапа работ",
            benefit3: "Учитываем и выполняем все пожелания клиентов"
        }
        
    ]

    useEffect(() => {
        if (isView)
            setHovered(true)
        if (isView1)
            setHovered1(true)
        if (isView2)
            setHovered2(true)
        if (isView3)
            setHovered3(true)
        if (isView4)
            setHovered4(true)
    }, [isView, isView1, isView2, isView3, isView4])

    const Figure1 = () =>
        <div className={st3.figuresBlock}>
            <div className={cn(st3.p, st3.pLight)}>
                <P/>
            </div>
            <div className={cn(st3.p, st3.pDark)}>
                <PDark/>
            </div>
            {/*<div className={st3.star}>*/}
            {/*    <Star/>*/}
            {/*</div>*/}
        </div>;

    const Figure2 = () =>
        <div className={st3.figuresBlock}>

            <div className={cn(st3.u, st3.uLight)}>
                <U/>
            </div>
            <div className={cn(st3.u, st3.uDark)}>
                <UDark/>
            </div>
        </div>;
    const Figure3 = () =>
        <div className={st3.figuresBlock}>
            <div className={cn(st3.y, st3.yLight)}>
                <Y/>
            </div>
            <div className={cn(st3.y, st3.yDark)}>
                <YDark/>
            </div>
        </div>;
    const Figure4 = () =>
        <div className={st3.figuresBlock}>
            <div className={cn(st3.k, st3.kLight)}>
                <K/>
            </div>
            <div className={cn(st3.k, st3.kDark)}>
                <KDark/>
            </div>
        </div>;

    return (
        <div className={st.aboutUsBG}>
            <div className={st.container} >
                <div className={st.aboutUs}>
                    <span className={st.aboutUsTitle}>ПОЧЕМУ ВЫБИРАЮТ НАС</span>
                    <div className={st.presentation}>


                        <MainLogoIcon hovered={hovered} setHovered={setHovered}/>
                        <div ref={myRef} className={cn(st.leftTextBlock , {[st.leftTextBlockHovered]: hovered})}><span>СОЗДАЙТЕ ДОМ</span></div>
                        <div className={cn(st.rightTextBlock , {[st.rightTextBlockHovered]: hovered})}><span>ВАШЕЙ МЕЧТЫ</span></div>

                        <div className={cn(st.ourBlock, {[st.hidden]: hovered1})} ref={myRef1} style={{zIndex: 10}}>
                            <OurBlock position={"firstBlock"} hovered={(hovered)} text={text[0]}><Figure1/></OurBlock>
                        </div>
                        <div className={cn(st.ourBlock, {[st.hidden]: hovered2})} ref={myRef2} style={{zIndex: 5}}>
                            <OurBlock position={"secondBlock"} hovered={hovered} text={text[1]}><Figure2/></OurBlock>
                        </div>
                        <div className={cn(st.ourBlock, {[st.hidden]: hovered4})} ref={myRef4}>
                            <OurBlock position={"forthBlock"} hovered={hovered} text={text[3]}><Figure4/></OurBlock>
                        </div>
                        <div className={cn(st.ourBlock, {[st.hidden]: hovered3})} ref={myRef3}>
                            <OurBlock position={"thirdBlock"} hovered={hovered} text={text[2]}><Figure3/></OurBlock>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;