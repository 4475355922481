import React from 'react';
import st from "./OurBlogs.module.css";
import BlogCard from "../../components/UI/BlogCard/BlogCard";

const OurBlogs = () => {

    const blogs = [
        {
            id: 0,
            date: "29 ноября 2022",
            title: "Lorem ipsum dolor sit ",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nobis odit placeat, ratione sapiente voluptates.",
            dateColor: "#656565",
            dateColorAfterHover: "#ffffff",
            titleColor: "#44546b",
            titleColorAfterHover: "#eaddbb",
            textColor: "#ffffff",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery.jpg")
        },
        {
            id: 1,
            date: "30 ноября 2022",
            title: "Deleniti nobis odit placeat",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nobis odit placeat, ratione sapiente voluptates.",
            dateColor: "#2b2b2d",
            dateColorAfterHover: "#ffffff",
            titleColor: "#44546b",
            titleColorAfterHover: "#dadada",
            textColor: "#bfcce0",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery2.jpg")
        },
        {
            id: 2,
            date: "16 ноября 2022",
            title: "Lorem ipsum dolor sit",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nobis odit placeat, ratione sapiente voluptates.",
            dateColor: "#2b2b2d",
            dateColorAfterHover: "#ffffff",
            titleColor: "#9b9692",
            titleColorAfterHover: "#eaddbb",
            textColor: "#ffffff",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery4.jpg")
        },
        {
            id: 3,
            date: "2 ноября 2022",
            title: "Accusantium ad incidunt ipsum",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nobis odit placeat, ratione sapiente voluptates.",
            dateColor: "#2b2b2d",
            dateColorAfterHover: "#ffffff",
            titleColor: "#4e7d85",
            titleColorAfterHover: "#b9f0fc",
            textColor: "#ffffff",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery5.jpg")
        },
        {
            id: 4,
            date: "29 ноября 2022",
            title: "Lorem ipsum dolor sit ",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nobis odit placeat, ratione sapiente voluptates.",
            dateColor: "#656565",
            dateColorAfterHover: "#ffffff",
            titleColor: "#44546b",
            titleColorAfterHover: "#eaddbb",
            textColor: "#ffffff",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery.jpg")
        },
        {
            id: 5,
            date: "30 ноября 2022",
            title: "Deleniti nobis odit placeat",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nobis odit placeat, ratione sapiente voluptates.",
            dateColor: "#2b2b2d",
            dateColorAfterHover: "#ffffff",
            titleColor: "#44546b",
            titleColorAfterHover: "#dadada",
            textColor: "#bfcce0",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery2.jpg")
        },
        {
            id: 6,
            date: "16 ноября 2022",
            title: "Lorem ipsum dolor sit",
            text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nobis odit placeat, ratione sapiente voluptates.",
            dateColor: "#2b2b2d",
            dateColorAfterHover: "#ffffff",
            titleColor: "#9b9692",
            titleColorAfterHover: "#eaddbb",
            textColor: "#ffffff",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery4.jpg")
        },
        {
            id: 7,
            date: "26 декабря 2022",
            title: "Личная подпись",
            text: "Бабыкин Александр Александрович, студент группы ИКБО-01-21",
            dateColor: "#2b2b2d",
            dateColorAfterHover: "#ffffff",
            titleColor: "#4e7d85",
            titleColorAfterHover: "#b9f0fc",
            textColor: "#ffffff",
            textColorAfterHover: "#ffffff",
            image: require("../../image/gallery/gallery5.jpg")
        },
    ]

    return (
        <div className={st.container}>
            <span className={st.title}>БЛОГ</span>
            <div className={st.blogBlock}>
                {blogs.map((blog, id) =>
                    <BlogCard blog={blog} key={id}/>
                )}
            </div>
        </div>
    );
};

export default OurBlogs;