import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../axios";
export const fetchCoordinates = createAsyncThunk("coordinates", async () => {
    const { data } = await axios.get('/coordinates');
    return data
})

const initialState = {
    coordinates: {
        items: [],
        status: 'loading'
    }
};

const coordinatesSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    // отлавливатели состояния запроса на сервер
    extraReducers: {
        [fetchCoordinates.pending]: (state) => {
            state.coordinates.items = [];
            state.coordinates.status = 'loading'
        },
        [fetchCoordinates.fulfilled]: (state, action) => {
            state.coordinates.items = action.payload;
            state.coordinates.status = 'loaded';
        },
        [fetchCoordinates.rejected]: (state) => {
            state.coordinates.items = [];
            state.coordinates.status = 'error';
        }, // действия относительно состояний загрузки данных
    }
})

export const coordinatesReducer = coordinatesSlice.reducer;