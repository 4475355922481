import React from 'react';
import {Provider} from 'react-redux'
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './redux/store';
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import {SkeletonTheme} from "react-loading-skeleton";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <>
      <SkeletonTheme>
          <BrowserRouter>
              <Provider store={store}>
                <ScrollToTop/>
                <App />
              </Provider>
          </BrowserRouter>
      </SkeletonTheme>
  </>
);


