import React, {useEffect, useState} from 'react';
import cn from "classnames";
import st from "./ProjectsMap.module.css";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import {useDispatch, useSelector} from "react-redux";
import {fetchCoordinates} from "../../redux/slices/coordinates";
import {Link, useHistory} from "react-router-dom";
import {ReactComponent as MapPoint1} from "../../image/icons/mapPoint1.svg";
import {ReactComponent as MapPoint2} from "../../image/icons/mapPoint2.svg";
import {ReactComponent as MapPoint3} from "../../image/icons/mapPoint3.svg";

const ProjectsMap = ({isOpened, filters, projects}) => {
    const dispatch = useDispatch();
    const {coordinates} = useSelector(state => state.coordinates)
    const [coordinatesWithStatus, setCoordinatesWithStatus] = useState([]);
    const [filteredCoordinates, setFilteredCoordinates] = useState(coordinates.items);
    const isCoordinatesLoading = coordinates.status === "loading";
    const history = useHistory();

    const [coordinatesFilter, setCoordinatesFilter] = useState({
        completed: false,
        withNoReport: false,
        inWork: false
    });
    
    const changeProjectFilters = (filterName) => {
        const newFilters = {};
        switch (filterName) {
            case ("completed"):
                coordinatesFilter.completed = !coordinatesFilter.completed
                coordinatesFilter.withNoReport = false
                coordinatesFilter.inWork = false
                break
            case ("withNoReport"):
                coordinatesFilter.withNoReport = !coordinatesFilter.withNoReport
                coordinatesFilter.completed = false
                coordinatesFilter.inWork = false
                break
            case ("inWork"):
                coordinatesFilter.inWork = !coordinatesFilter.inWork
                coordinatesFilter.completed = false
                coordinatesFilter.withNoReport = false
                break    
        }
        for (const key in coordinatesFilter) { // копируем
            newFilters[key] = coordinatesFilter[key];
        }
        setCoordinatesFilter(newFilters); // сетим
    }
    
    useEffect(() => {
        if (coordinates.items.length !== 0 && projects.items.length !== 0) {
            let coordinatesWithStatus2 = setCoordinatesTypes(coordinates.items);
            setCoordinatesWithStatus(coordinatesWithStatus2)
            setFilteredCoordinates(coordinatesWithStatus2)
        }
    }, [])

    useEffect(() => {
        if (coordinates.items.length === 0) {
            dispatch(fetchCoordinates())
        }
    }, [dispatch])

    useEffect(() => {
        if (coordinates.items.length !== 0 && projects.items.length !== 0) {
            let coordinatesWithStatus2 = setCoordinatesTypes(coordinates.items);
            setCoordinatesWithStatus(coordinatesWithStatus2)
            setFilteredCoordinates(coordinatesWithStatus2)
            
        }
    }, [coordinates, projects])
    
    const setCoordinatesTypes = (coordinates) => {
        return coordinates.map((coord) => {
            let project = projects.items.filter((project) => (project.id === coord?.projectId))[0];
            if (!project) {
                return {
                    ...coord,
                    status: "withNoReport"
                }
            } else {
                if (!project?.visible) {
                    return {
                        ...coord,
                        status: "isNotVisible"
                    }
                }
                else if (!project?.completed) {
                    return {
                        ...coord,
                        status: "inWork"
                    }
                }
                else if (project?.completed) {
                    return {
                        ...coord,
                        status: "completed"
                    }
                }
            }
        }).filter(coords => {
            return coords.status !== "isNotVisible"
        })
    }

    useEffect(() => {
        refilterCoordinates();
    }, [coordinatesFilter])
    
    const refilterCoordinates = () => {
        if (coordinatesWithStatus) {
            if (coordinatesFilter.completed) {
                setFilteredCoordinates(coordinatesWithStatus.filter((coord) => coord.status === "completed"))
            } else if (coordinatesFilter.withNoReport) {
                setFilteredCoordinates(coordinatesWithStatus.filter((coord) => coord.status === "withNoReport"))

            } else if (coordinatesFilter.inWork) {
                setFilteredCoordinates(coordinatesWithStatus.filter((coord) => coord.status === "inWork"))

            } else {
                setFilteredCoordinates(coordinatesWithStatus)
            }
        }
    }

    return (
        <div className={st.fullContainer}>
            <div className={st.container}>
                <div className={cn(st.mapBlock, {[st.mapBlockOpened]: isOpened})}>
                    <div className={st.mapButtonsBlock}>
                        <div title="Проекты с описанием" className={cn(st.mapButton, {[st.mapButtonHovered]: coordinatesFilter.completed})} onClick={() => changeProjectFilters("completed")}>
                            <MapPoint1 className={st.point}/>
                        </div>
                        <div title="Проекты в архиве" className={cn(st.mapButton, {[st.mapButtonHovered]: coordinatesFilter.withNoReport})} onClick={() => changeProjectFilters("withNoReport")}>
                            <MapPoint2 className={st.point}/>
                        </div>
                        <div title="Активные проекты" className={cn(st.mapButton, {[st.mapButtonHovered]: coordinatesFilter.inWork})} onClick={() => changeProjectFilters("inWork")}>
                            <MapPoint3 className={st.point}/>
                        </div>
                        {/*<div className={st.mapButton}>*/}
                        
                        {/*</div>*/}
                    </div>
                    <YMaps>
                        <Map
                            defaultState={{
                                center: [55.755814, 37.617635], // Координаты по умолчанию
                                zoom: 10, // Масштаб по умолчанию
                            }}
                            className={st.map}
                        >
                            {(isCoordinatesLoading ? [] : (filteredCoordinates.length !== 0 ? filteredCoordinates : setCoordinatesTypes(coordinates.items))).map((coordinate) =>
                                <Placemark
                                    key={coordinate.id}
                                    geometry={[coordinate.latitude, coordinate.longitude]} // Координаты адреса
                                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                    properties={{
                                        hintContent: coordinate.hoverInfo,
                                        balloonContent: coordinate.info, // Дополнительная информация для всплывающей подсказки
                                    }}
                                    options={{
                                        preset: "islands#icon",
                                        iconColor: coordinate.status === "withNoReport" ? "#0059ff" : coordinate.status === "completed" ? "#ff0000" : "#5eca12", // Цвет метки
                                    }}
                                    onClick={() => {
                                        if (coordinate.projectId !== null)
                                            history.push(`/projects/${coordinate.projectId}`);
                                    }}
                                ></Placemark>
                            )}
                        </Map>
                    </YMaps>
                </div>
            </div>
        </div>
    );
};

export default ProjectsMap;