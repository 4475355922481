import styles from "./Navbar.module.css"
import {ReactComponent as Logo} from "../../image/logo.svg";
import {Link, NavLink} from "react-router-dom";
import {ReactComponent as Instagram} from "../../image/instagram.svg";
import {ReactComponent as Whatsapp} from "../../image/whatsapp.svg";
// import {ReactComponent as Burger} from "../../image/icons/burger.svg";
import cn from "classnames";
import {useEffect, useState} from "react";
import {useTheme} from '../../hooks/useTheme'
import {ReactComponent as Sun} from "../../image/icons/sun.svg";
import {ReactComponent as Moon} from "../../image/icons/moon.svg";

const Navbar = () => {
    const {theme, setTheme} = useTheme();
    const [scroll, setScroll] = useState(0);
    const [isOpenedMenu, setIsOpenedMenu] = useState(false);
    const [isOpenedHeader, setIsOpenedHeader] = useState(true);

    /* Method that will fix header after a specific scrollable */
    const isSticky = () => {
        if (window.scrollY <= scroll || isOpenedMenu || window.scrollY <= 90) {
            setIsOpenedHeader(true)
        } else {
            setIsOpenedHeader(false)
        }
        setScroll(window.scrollY);
    };


    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const closeNavbar = () => {
        if (isOpenedMenu)
            setIsOpenedMenu(!isOpenedMenu);
    }
    
    const changeTheme = () => {
        if (theme === 'dark') setTheme('light')
        else setTheme('dark')
    }

    // const reloadPage = () => {
    //     console.log("reload")
    //     window.location.reload()
    // }

    return (
        <>
            <div className={styles.shadow_nav} id={"header"}></div>
            <div className={cn(styles.navbar, {[styles.navbar_opened]: isOpenedHeader})} >
                <div className={styles.container}>
                    <div className={styles.menu}>   {/* сама менюшка с кнопками и лого */}

                        <div className={styles.logoBlock}>
                            <Link to='/home'><Logo className={styles.logo}/></Link>
                            <Link to='/home' className={styles.logo_text}>
                                <span>РЕМОНТ</span>
                                <span>ИННОВАЦИИ</span>
                                <span>КОМФОРТ</span>
                                <span>УДОВОЛЬСТВИЕ</span>
                            </Link>
                        </div>

                        {/*<Burger onClick={() => setIsOpened(!isOpened)}/>*/}
                        <div className={styles.burger_block} onClick={() => setIsOpenedMenu(!isOpenedMenu)}>
                            <svg className={cn(styles.burger, {[styles.burger_opened]: isOpenedMenu})} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line className={styles.burgerFirstLine} x1="2" y1="5" x2="22" y2="5" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                <line className={styles.burgerSecondLine} x1="2" y1="12" x2="22" y2="12" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                <line className={styles.burgerThirdLine} x1="2" y1="19" x2="22" y2="19" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>


                        <div onClick={closeNavbar} className={cn(styles.shadow, {[styles.shadow_opened]: isOpenedMenu})}>
                        </div>
                        <div className={cn(styles.navbar_buttons, {[styles.navbar_buttons_opened]: isOpenedMenu})}>
                            <NavLink className={styles.navbarLink} to='/home' onClick={closeNavbar}  activeClassName={styles.active_link}><span>ГЛАВНАЯ</span></NavLink>
                            <NavLink className={styles.navbarLink} to='/projects' onClick={closeNavbar}  activeClassName={styles.active_link}><span>НАШИ ПРОЕКТЫ</span></NavLink>
                            {/*<NavLink to='/blog' onClick={closeNavbar}  activeClassName={styles.active_link}><span>Блог</span></NavLink>*/}
                            <a className={styles.navbarLink} href={"#feedback"} onClick={closeNavbar} ><span>СВЯЗАТЬСЯ С НАМИ</span></a>
                            <NavLink className={styles.navbarLink} to='/contacts' onClick={closeNavbar}  activeClassName={styles.active_link}><span>КОНТАКТЫ</span></NavLink>

                            <div onClick={changeTheme} className={styles.changeThemeToggle}>
                                <div className={styles.toggle}>
                                    <Sun className={styles.iconSunTheme}/>
                                    <Moon className={styles.iconMoonTheme}/>
                                </div>
                            </div>
                            <div className={styles.social_webs}>
                                <a target="_blank" href={'https://wa.me/79891070874?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21'}>
                                    <Whatsapp className={styles.whatsapp}/>
                                </a>
                                {/*<Whatsapp className={styles.whatsapp}/>*/}
                            </div>
                        </div>

                    </div>
                </div>
                <hr/>
            </div>
        </>
    );
};

export default Navbar;